import { connect } from 'react-redux';

import {
  createOrUpdateSubmission, deleteSubmissionFile
} from '../../../actions/student-submissions';
import { formApiAction } from '../../../actions/api';
import SubmissionCreate from './submission-create';

const mapStateToProps = (state) => ({
  submissionCreated: state.submissions.submissionCreated,
});

const mapDispatchToProps = (dispatch) => {
  const formCreateOrUpdateSubmission = formApiAction(createOrUpdateSubmission);
  return {
    createOrUpdateSubmission: (formData, setError) => {
      dispatch(formCreateOrUpdateSubmission(formData, setError));
    },
    deleteSubmissionFile: (id) => dispatch(deleteSubmissionFile(id)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SubmissionCreate);
