import React from 'react';

import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';

import Avatar from '../avatar/avatar';

import styles from './user-list-item.module.scss';


const UserListItem = (props) => {
  const {
    user
  } = props;

  const history = useHistory();

  const handleClick = () => {
    history.push(`/participants/${user.id}`);
  };

  return (
    <div
      className={styles.container}
      onClick={handleClick}
    >

      <div className={styles.item}>
        <div className={styles.switch}>
          <Avatar
            name={user.display_name}
          />
        </div>
        <div
          className={styles.content}
        >
          <div className={styles.title}>
            {user.first_name}
            {' '}
            {user.last_name}
          </div>
          <div className={styles.subtitle}>{user.real_email}</div>
        </div>
        {/*
        <div className={styles.stats}>
          <div className={styles.stat}>
            <span className={styles.statNumber}>
              12
            </span>
            <span className={styles.statTitle}>
              Submissions
            </span>
          </div>
          <div className={styles.stat}>
            <span className={styles.statNumber}>
              57
              <span className={styles.statPercentage}>%</span>
            </span>
            <span className={styles.statTitle}>
              Completed
            </span>
          </div>
        </div>
*/}
        <div className={styles.actions}>
        </div>
      </div>
    </div>
  );
};

UserListItem.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

UserListItem.defaultProps = {
  title: '',
  subtitle: '',
};

export default UserListItem;
