/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDropzone } from 'react-dropzone';
import classNames from 'classnames';

import AddSquare from '../../assets/icons/add-circle.svg';

import styles from './styles.module.scss';

const SubmissionFileUpload = (props) => {
  const { setSelectedFiles, selectedFiles, label } = props;
  const { acceptedFiles, getRootProps, getInputProps, isDragActive } = useDropzone();

  const readFiles = (files) => {
    for (let i = 0, f; f = files[i]; i += 1) {
      const reader = new FileReader();
      reader.onabort = () => console.log('file reading was aborted');
      reader.onerror = () => console.log('file reading has failed');
      reader.onload = (function (theFile) {
        return function (e) {
          setSelectedFiles(
            selectedFiles.concat({
              file: e.target.result,
              name: f.name,
            })
          );
        };
      }(f));

      reader.readAsDataURL(f);
      //reader.readAsText(f);
    }
  };

  useEffect(() => {
    if (acceptedFiles.length > 0) {
      readFiles(acceptedFiles);
    }
  }, [acceptedFiles]);

  return (
    <div className={classNames(
      styles.container, {
      [styles.isDragActive]: isDragActive
    }
    )}>
      <div {...getRootProps({ className: 'dropzone' })}>
        <input {...getInputProps()} />
        <AddSquare />
        <span className={styles.label}>
          {isDragActive ?
            'Drop it!' : label
          }
        </span>
      </div>
    </div>
  );
};

SubmissionFileUpload.propTypes = {
  setSelectedFiles: PropTypes.func.isRequired,
  label: PropTypes.string
};

SubmissionFileUpload.defaultProps = {
  label: 'Drag \'n\' drop or click to add a file (optional)'
};

export default SubmissionFileUpload;
