import React from 'react';
import { Redirect, Route } from 'react-router';
import { Provider, ErrorBoundary } from '@rollbar/react';
import { useQuery } from 'react-query';
import { getMe, userQuerySettings } from '../queries/user';
import getRollbarConfig from '../common/rollbarSettings';

const ProtectedRoute = (props) => {
  const user = useQuery('me', getMe, userQuerySettings);

  if (user.isLoading) {
    return <div>Loading...</div>;
  }

  if (user.isError) {
    return <Redirect to="/login" />;
  }

  let rollbarConfig = getRollbarConfig();
  rollbarConfig.payload.person = {
    id: user.data.id,
    email: user.data.email,
    is_hijacked: user.data.is_hijacked_user,
  }

  return (
    <Provider config={rollbarConfig}>
      <ErrorBoundary>
        <Route {...props} />
      </ErrorBoundary>
    </Provider>
  );
};


export default ProtectedRoute;
