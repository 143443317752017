import React from 'react';
import { useParams } from 'react-router-dom';

import { longDate } from '../../../common/utils';
import { useSubmissionsForCourse } from '../../../queries/admin-queries';
import SubmissionListItem from '../../../components/submission-list-item/submission-list-item';
import List from '../../../components/list/list';

import styles from './course-detail.module.scss';

const CourseDetailOverview = (props) => {
  const { id } = useParams();
  const submissions = useSubmissionsForCourse(id)

  if (submissions.isLoading) {
    return <p>Loading...</p>;
  }

  return (
    <section className={styles.section}>
      <List>
        {submissions.data.results.length === 0 && (
          <h3>This Course currently has no submissions</h3>
        )}
        {submissions.data.results.map((item, index) => (
          <SubmissionListItem
            url="submissions"
            id={item.id}
            title={`${item.author.first_name} ${item.author.last_name}`}
            subtitle={longDate(item.created)}
            footer={item.assignment}
            imageURL={item.thumbnail}
            key={item.id}
          />
        ))}
      </List>
    </section>
  );
};

export default CourseDetailOverview;
