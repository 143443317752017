import React from 'react';
import { Link, useParams } from 'react-router-dom';

import SectionListItem from '../../../components/section-list-item/section-list-item';
import { useUser } from '../../../queries/user';
import { useSectionsForCourse } from '../../../queries/admin-queries';
import styles from './course-detail.module.scss';


const CourseDetailOverview = (props) => {
  const { id } = useParams();
  const user = useUser();
  const sections = useSectionsForCourse(id);

  if (user.isLoading || sections.isLoading) {
    return (<div>Loading...</div>);
  }

  return (
    <React.Fragment>
      <div className={styles.actions}>
        <div className="container">
          {!user.data.is_lti &&
            <Link
              className={styles.smallButton}
              to={{ pathname: `/sections/create/${id}`, state: { modal: true } }}
            >
              Add a new Section
            </Link>
          }
        </div>
      </div>
      <section className={styles.section}>
        <div className="container">
          {sections.length === 0 && (
            <h3>This Course currently has no sections</h3>
          )}
          {sections.data.results.map((item, index) => (
            <SectionListItem
              isActive={item.is_active}
              id={item.id}
              title={item.name}
              subtitle={item.description}
              studentCount={item.student_count}
              key={item.id}
            />
          ))}
        </div>
      </section>
    </React.Fragment>
  );
};


export default CourseDetailOverview;
