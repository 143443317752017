import React from 'react';
import { useQuery } from 'react-query';
import { getMe, userQuerySettings } from '../../queries/user';
import PlatformName from '../../components/platform';
import styles from './footer.module.scss';


const Footer = (props) => {
  const {
    name, small, medium, large, href, badge
  } = props;
  const user = useQuery('me', getMe, userQuerySettings);

  if (user.isLoading) {
    return ('Loading...');
  }

  // HBP users shouldn't get a footer
  if (user.data.is_hbp) {
    return (<></>);
  }

  return (
    <div className={styles.container}>
      <div className="container">
        <div className="flex">
          <div className="w-1/4">
            <h4><PlatformName /></h4>
            <ul>
              <li><a href="https://peer-learning-venture.com/">About us</a></li>
              <li><a href="/help/">Help</a></li>
              <li><a href="mailto:Help@PeerLearningVenture.com">Email Technical Support</a></li>
            </ul>
          </div>
          <div className="w-1/4">
            {/*
            <h4>Resources</h4>
            <ul>
              <li>Terms</li>
              <li>sadfsafas</li>
              <li>Contact Us</li>
            </ul>
            */}
          </div>
          <div className="w-1/4">
            {/*
            <h4>Lorem Ipsum</h4>
            <ul>
              <li>Hi there!</li>
              <li>Oh a List Item</li>
              <li>Another one</li>
              <li>And Another one</li>
            </ul>
            */}
          </div>
          <div className="w-1/4">
            {/*
            <h4>Lorem Ipsum dolor sit</h4>
            <ul>
              <li>Carreer</li>
              <li>Contact us</li>
              <li>Pricing</li>
              <li>Security</li>
            </ul>
            */}
          </div>
        </div>
        <div className="flex">
          <span className={styles.subline}>© 2024 Peer Exchange</span>
        </div>
      </div>
    </div>
  );
};

export default Footer;
