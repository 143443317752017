import React from 'react';
import { useHistory, useParams, Link } from 'react-router-dom';
import { useQuery } from 'react-query';
import { getMe, userQuerySettings } from '../../../queries/user';
import { getAssignment, getAssignmentResults } from '../../../queries/student_assignments';
import classNames from 'classnames';
import Loading from '../../../components/loading/loading';
import Navigation from '../../../components/navigation/navigation';
import ScrollArea from '../../../components/scroll-area/scroll-area';
import styles from './results.module.scss';

const buttonClasses = classNames("rounded py-2 px-4 ml-4 text-lg text-white bg-blue-500 hover:bg-blue-700 cursor-pointer");

const NotAvailable = (props) => {
  return (
    <div className="rounded bg-blue-100 p-4 w-full my-4">
      <p className="py-2">
        You may view your submission from the link to the right.
      </p>
    </div>
  )
}

const DisplayReview = (props) => {
  const { review, index } = props;
  const items = [];
  review.qualitative.map((item) => {
    items.push(<p className="font-bold my-1" key={`label-${item.id}`}>{item.label}</p>);
    items.push(<p className="my-1" key={`answer-${item.id}`}>{item.answer}</p>);
  })

  let css = "my-4 p-1 border border-1 border-grey-400 bg-gray-100 p-4";
  let title = `#${index + 1} Review`;

  if (review.is_ai) {
    css = "my-4 p-1 border border-1 p-4 border-red-400 bg-red-200"
    title = `#${index + 1} AI Review`;
  }

  try {
    return (
      <div className={css}>
        <h4 className="text-lg font-bold">{title}</h4>
        {items}
        {!items.length && (<span>Your assignment had no textual review questions to display</span>)}
      </div>
    )

  } catch (e) {
    console.dir(e);
  }
}

const DisplayReviewResults = (props) => {
  const { reviews } = props;

  return (
    <div className="my-6">
      <h4 className="text-2xl font-bold border border-1 border-grey-400 border-solid p-2 bg-gray-200">
        Reviews of your Stage 1 Submission
      </h4>
      {reviews.map((object, i) => <DisplayReview review={object} index={i} key={`drr-${i}`} />)}
    </div>
  )
}

const Results = (props) => {
  const { id } = useParams();
  const history = useHistory();
  const user = useQuery('me', getMe, userQuerySettings);
  const assignment = useQuery(['student-assignment', id], () => getAssignment(id));
  const results = useQuery(['student-assignment-results', id], () => getAssignmentResults(id));

  if (user.isLoading || assignment.isLoading || results.isLoading) {
    return <Loading />
  }

  let reviewTitleText = "Review"
  if (results.data.reviews.length > 1) {
    reviewTitleText = "Reviews"
  }

  // Don't show results if the class is paused
  if (assignment.data.paused && !user.data.is_hijacked_user) {
    return (
      <div className={styles.container}>
        <Navigation
          history={history}
          title="Assignment Results"
          backURL="/dashboard"
          hasBackButton
        />
        <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 m-4 rounded relative" role="alert">
          <span><strong>Class is currently paused</strong></span>
          <br />
          {assignment.data.pause_message && <p className="p-4">{assignment.data.pause_message}</p>}
        </div>
      </div>
    );
  }

  // Show the results
  return (
    <div className={styles.container}>
      <Navigation
        history={history}
        title="Assignment Results"
        backURL="/dashboard"
        hasBackButton
      />
      <ScrollArea>
        <div className={styles.content}>
          <div className={styles.welcome}>
            <h3 className="p-2">
              Here is feedback on your Submission and Reviews
            </h3>
            <Link to={`/results/${id}/submission`} className={buttonClasses}>View Your Submission</Link>
            {assignment.data.show_review_comparison &&
              <Link to={`/results/${id}/compare`} className={buttonClasses}>Compare Your Stage 2 Reviews to Others'</Link>
            }
            {assignment.data.show_student_results &&
              <Link to={`/results/${id}/feedback`} className={buttonClasses}>
                Learn others' feedback on your Stage 2 Reviews
              </Link>
            }
          </div>
          {!assignment.data.show_students_results && <NotAvailable />}
          {assignment.data.show_students_results && <DisplayReviewResults reviews={results.data.reviews} />}

          {assignment.data.release_ai_critical_thinking_feedback && results.data.submission.ai_critical_thinking_feedback && (
            <div className="my-6">
              <h4 className="text-2xl font-bold border border-1 border-red-400 border-solid p-2 bg-red-200">
                ChatGPT Critical Thinking Feedback
              </h4>
              <div className="my-4 p-1 border border-1 border-red-400 bg-red-100 p-4" style={{ "whiteSpace": "pre-line" }}>
                {results.data.submission.ai_critical_thinking_feedback}
              </div>
            </div>
          )}
          {assignment.data.release_ai_writing_feedback && results.data.submission.ai_writing_feedback && (
            <div className="my-6">
              <h4 className="text-2xl font-bold border border-1 border-red-400 border-solid p-2 bg-red-200">
                ChatGPT Writing Feedback
              </h4>
              <div className="my-4 p-1 border border-1 border-red-400 bg-red-100 p-4" style={{ "whiteSpace": "pre-line" }}>
                {results.data.submission.ai_writing_feedback}
              </div>
            </div>
          )}
        </div>
      </ScrollArea>
    </div>
  )
};

export default Results;
