import { useQuery } from 'react-query';
import {
  getApiRequest,
  postApiRequest,
  putApiRequest,
  deleteApiRequest,
  patchApiRequest,
} from './base'

export const getHelp = async (path) => {
  return getApiRequest(`/api/help/?path=${path}/`);
}

export const getFormHelp = async (form) => {
  return getApiRequest(`/api/formhelp/?form=${form}/`);
}

export const formHelpQuerySettings = {
  cacheTime: 60000,
  staleTime: 60000,
};
