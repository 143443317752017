import React, { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import classNames from 'classnames';
import moment from 'moment';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import { useUser } from '../../../queries/user';
import { useOralAssignment, useStartedOralAssignment, uploadStandAloneOral } from '../../../queries/student_assignments';
import Navigation from '../../../components/navigation/navigation';
import Button from '../../../components/button/button';
import Loading from '../../../components/loading/loading';
import { useRollbar } from '@rollbar/react';
import Countdown from 'react-countdown';
import Webcam from "react-webcam";
import { determineCameraPermission } from '../oral-create/detect';

const WebcamStreamCapture = ({ assignmentID, endTime, questionID, oralID }) => {
  const webcamRef = React.useRef(null);
  const mediaRecorderRef = React.useRef(null);
  const queryClient = useQueryClient();
  const history = useHistory();
  const rollbar = useRollbar();
  const [hasPermission, setHasPermission] = useState(false);
  const [capturing, setCapturing] = React.useState(false);
  const [uploading, setUploading] = React.useState(false);
  const [recordedChunks, setRecordedChunks] = React.useState([]);

  // Determine when we need to cut off the user
  const target = moment(endTime)
  const now = moment()
  const diffSeconds = target.diff(now);

  const audioConstraints = {
    echoCancellation: false,
    autoGainControl: false,
    noiseSuppression: false,
    audioBitsPerSecond: 256000,
    audioBitRateMode: "constant",
  }

  const upload = useMutation(uploadStandAloneOral, {
    onSettled: (data, error, variables, context) => {
      setUploading(false);
      queryClient.invalidateQueries(['student-oral-assignment']);
    },
    onSuccess: (data, variables, context) => {
      history.push(`/my-oral-assignments/${assignmentID}`);
    },
    onError: (data, error, variables, context) => {
      rollbar.error(error, context);
    }
  })

  const handleStartCaptureClick = React.useCallback(() => {
    setCapturing(true);
    const mimeType = MediaRecorder.isTypeSupported("video/webm") 
      ? "video/webm" 
      : "video/webm;codecs=vp8,opus";
      
    mediaRecorderRef.current = new MediaRecorder(webcamRef.current.stream, {
      mimeType: mimeType,
      videoBitsPerSecond: 2500000, // 2.5 Mbps
    });
    mediaRecorderRef.current.addEventListener(
      "dataavailable",
      handleDataAvailable
    );
    mediaRecorderRef.current.start();
  }, [webcamRef, setCapturing, mediaRecorderRef]);

  const handleDataAvailable = React.useCallback(
    ({ data }) => {
      console.log("In handle data")
      if (data.size > 0) {
        setUploading(true);
        setRecordedChunks((prev) => prev.concat(data));
        console.log("Got chunk!", data)
        const blob = new Blob([data], {
          type: "video/webm"
        });
        console.log("blob")
        console.dir(blob)
        console.log("chunks")
        console.dir(recordedChunks)
        upload.mutate({
          id: assignmentID,
          oralID: oralID,
          questionID: questionID,
          videoBlob: blob
        });
      }
    },
    [setRecordedChunks]
  );

  const handleStopCaptureClick = React.useCallback(() => {
    mediaRecorderRef.current.stop();
    setCapturing(false);
  }, [recordedChunks]);

  navigator.permissions.query({ name: 'camera' }).then((result) => {
    if (result.state === "granted") {
      setHasPermission(true);
    }

    if (result.state === "denied") {
      setPermissionDenied(true);
    }
  }).catch((error) => {
    // Likely firefox which doesn't support permissions API the same way
    navigator.mediaDevices
      .getUserMedia({ audio: true, video: true })
      .then((mediaStream) => {
        setHasPermission(true);
      })
      .catch((error) => {
        setHasPermission(false);
      })
  })
  console.log("hasPermission", hasPermission)

  // Set up timer to cut off user at the appropirate time
  useEffect(() => {
    let timeOutID = null;

    timeOutID = setTimeout(() => {
      console.log("Cutting off user!")
      handleStopCaptureClick();
    }, diffSeconds * 1000);

    return () => {
      if (timeOutID) clearTimeout(timeOutID);
    }
  }, [diffSeconds]);

  return (
    <>
      {!hasPermission && (
        <div className="my-6 w-full p-4 bg-red-100 border border-red-300 text-xl">
          You must grant permission to use your camera and microphone in your browser to record your oral assignment.
          You should see a popup message asking for your permission.
        </div>
      )}
      {uploading && (
        <div className="my-6 w-full p-4 bg-red-100 border border-red-300 text-xl">
          Uploading! Do not close your browser tab or navigate away until this is complete.
        </div>
      )}
      {!uploading && (
        <>
          <div className="w-full flex justify-center">
            <Webcam
              audio={true}
              muted={true}
              ref={webcamRef}
              mirrored={true}
              audioConstraints={audioConstraints}
            />
          </div>
          <div className="mt-8 mb-24 w-full flex justify-center">

            {capturing ? (
              <button
                className="px-4 py-3 rounded text-white bg-red-500 hover:bg-red-700"
                onClick={handleStopCaptureClick}>
                Stop Recording and Submit Oral Assignment
              </button>
            ) : (
              <button
                className="px-4 py-3 rounded text-white bg-green-500 hover:bg-green-700"
                onClick={handleStartCaptureClick}>
                Start Recording
              </button>
            )}
          </div>
        </>
      )}
    </>
  );
};

export const OralStart = () => {
  const { id, questionID } = useParams();
  const [showCutoffCountdown, setShowCutoffCountdown] = useState(false);
  const history = useHistory();
  const rollbar = useRollbar();
  const user = useUser();
  const assignment = useOralAssignment(id);
  const started = useStartedOralAssignment(id, questionID);

  const startTime = moment(started.data?.timer_started || moment());
  const endTime = startTime + 5 * 60 * 1000;
  const showCutOffTime = startTime + 5.5 * 60 * 1000;
  const cutOffTime = startTime + 6 * 60 * 1000;
  const diffSeconds = moment.duration(moment(showCutOffTime).diff(moment())).asSeconds();;

  useEffect(() => {
    let timeOutID = null;

    timeOutID = setTimeout(() => {
      console.log("Setting showCutOffCountdown to true");
      setShowCutoffCountdown(true);
    }, diffSeconds * 1000);

    return () => {
      if (timeOutID) clearTimeout(timeOutID);
    }
  }, [diffSeconds]);

  if (assignment.isLoading || user.isLoading || started.isLoading) {
    return <Loading />;
  }

  if (moment.duration(moment(cutOffTime).diff(moment())).asSeconds() <= 0) {
    return (
      <div>
        <Navigation
          history={history}
          title={assignment.data.title}
          backURL={`/my-oral-assignments/${id}`}
          hasBackButton
        />
        <div className="p-4">
          <div className="my-2">
            <h2 className="text-2xl font-bold border border-1 border-grey-400 border-solid p-2 bg-gray-200">
              Oral Assignment Started
            </h2>
          </div>
          <div className="w-full mt-8 p-6 bg-gray-100 border border-gray-400 mb-8">
            <h2 className="text-2xl font-semibold">Your Question:</h2>
            <p className="mt-6">
              <span className="text-2xl italic">{started.data.question_text}</span>
            </p>
          </div>
          <div className="w-full mt-8">
            <div className="border border-red-500 bg-red-100 p-6">
              <strong>Time has elapsed for this question already.</strong>
            </div>
          </div>
        </div>
      </div>
    )
  }

  return (
    <div>
      <Navigation
        history={history}
        title={assignment.data.title}
        backURL={`/my-assignments/${id}`}
        hasBackButton
      />
      <div className="p-4">
        <div className="my-2">
          <h2 className="text-2xl font-bold border border-1 border-grey-400 border-solid p-2 bg-gray-200">
            Oral Assignment Started
          </h2>
        </div>

        <div className="w-full mt-8 p-4 bg-blue-100 border border-blue-300 text-center mb-8">
          {startTime.isValid() && !showCutoffCountdown && (
            <Countdown
              date={endTime}
              overtime={true}
              renderer={({ hours, minutes, seconds, completed }) => {
                if (completed) {
                  return (<span className="font-bold text-xl text-red-500">Time Over Deadline: {minutes} minutes {seconds} seconds</span>)

                } else {
                  return (<span className="font-bold text-xl">Time Remaining: {minutes} minutes {seconds} seconds</span>)
                }
              }}
            />
          )}
          {showCutoffCountdown && (
            <Countdown
              date={cutOffTime}
              overtime={false}
              renderer={({ hours, minutes, seconds, completed }) => {
                return (<span className="font-bold text-xl text-red-500 ">Your recording will be cut off in: {minutes} minutes {seconds} seconds!</span>)
              }}
            />

          )}
        </div>

        <div className="w-full mt-8 p-6 bg-gray-100 border border-gray-400 mb-8">
          <h2 className="text-2xl font-semibold">Your Question:</h2>
          <p className="mt-6">
            <span className="text-2xl italic">{started.data.question_text}</span>
          </p>
        </div>

        <WebcamStreamCapture
          assignmentID={id}
          questionID={questionID}
          oralID={started.data.id}
          endTime={cutOffTime}
        />
      </div>
    </div>
  );
}


export default OralStart;
