import { useQuery } from 'react-query';
import {
  getApiRequest,
  postApiRequest,
  putApiRequest,
  deleteApiRequest,
  patchApiRequest,
} from './base'

export const getSubmission = async (submissionId) => {
  return getApiRequest(`/api/admin/submissions/${submissionId}/`);
};

export const getAssignmentDefaults = {
  cacheTime: 600000,
  staleTime: 60000,
  refetchInterval: (data) => !data || data.state.includes('adv_') ? 2000 : undefined,
};

export const getAssignment = async (assignmentId) => {
  return getApiRequest(`/api/admin/assignments/${assignmentId}/`);
};

export const createAssignment = async (formData) => {
  return postApiRequest('/api/admin/assignments/', formData);
};

export const updateAssignment = async (assignmentId, formData) => {
  return patchApiRequest(`/api/admin/assignments/${assignmentId}/`, formData);
};

export const getAssignmentSubmissions = async (assignmentId, currentPage, filter) => {
  return getApiRequest(`/api/admin/assignments/${assignmentId}/submissions/?page=${currentPage}&filter=${filter}`);
};

export const getAssignmentReviews = async (assignmentId, currentPage, filter) => {
  return getApiRequest(`/api/admin/assignments/${assignmentId}/reviews/?page=${currentPage}&filter=${filter}`);
};

export const getAssignmentFeedbacks = async (assignmentId, currentPage, filter) => {
  return getApiRequest(`/api/admin/assignments/${assignmentId}/feedbacks/?page=${currentPage}&filter=${filter}`);
};

export const getAssignmentSubmissionCounts = async (assignmentId) => {
  return getApiRequest(`/api/admin/assignments/${assignmentId}/submissioncounts/`);
};

export const getAssignmentReviewCounts = async (assignmentId) => {
  return getApiRequest(`/api/admin/assignments/${assignmentId}/reviewcounts/`);
};

export const getAssignmentFeedbackCounts = async (assignmentId) => {
  return getApiRequest(`/api/admin/assignments/${assignmentId}/feedbackcounts/`);
};

export const getAssignmentReviewDistribution = async (assignmentId) => {
  return getApiRequest(`/api/admin/assignments/${assignmentId}/reviewdistribution/`);
};

export const getAssignmentFeedbackDistribution = async (assignmentId) => {
  return getApiRequest(`/api/admin/assignments/${assignmentId}/feedbackdistribution/`);
};

export const getAssignmentParticipation = async (assignmentId) => {
  return getApiRequest(`/api/admin/assignments/${assignmentId}/participation/`);
};

export const getAssignmentStudentReport = async (assignmentId, stage, bands) => {
  return getApiRequest(`/api/admin/assignments/${assignmentId}/student_report/?stage=${stage}&bands=${bands}`);
};

export const getAssignmentAICriticalThinking = async (assignmentId) => {
  return getApiRequest(`/api/admin/assignments/${assignmentId}/ai_critical_thinking/`);
};

export const getAssignmentAIWriting = async (assignmentId) => {
  return getApiRequest(`/api/admin/assignments/${assignmentId}/ai_writing/`);
};

export const getLeagues = async (assignmentId) => {
  return getApiRequest(`/api/admin/assignments/${assignmentId}/leagues/`);
};

export const getLeague = async (leagueID) => {
  return getApiRequest(`/api/admin/leagues/${leagueID}/details/`);
};

export const getReview = async (id) => {
  return getApiRequest(`/api/admin/reviews/${id}/`);
};

export const getUserDetail = async (id) => {
  return getApiRequest(`/api/users/${id}/`);
};

export const togglePause = async (data) => {
  return patchApiRequest(`/api/admin/assignments/${data.id}/pause/`, {
    pause: data.pause,
    pause_message: data.message,
  });
};

export const sendReminderEmail = async (data) => {
  return postApiRequest(`/api/admin/assignments/${data.id}/send_reminder_email/`);
};

export const sendDelayedEmail = async (data) => {
  return postApiRequest(`/api/admin/assignments/${data.id}/send_delayed_stage_email/`);
};

export const transitionToSubmission = async (data) => {
  return patchApiRequest(`/api/admin/assignments/${data.id}/to_submission_state/`);
};

export const transitionToReview = async (data) => {
  return patchApiRequest(`/api/admin/assignments/${data.id}/to_review_state/`);
};

export const transitionToReviewFeedback = async (data) => {
  return patchApiRequest(`/api/admin/assignments/${data.id}/to_review_feedback_state/`);
};

export const transitionToOral = async (data) => {
  return patchApiRequest(`/api/admin/assignments/${data.id}/to_oral_state/`);
};

export const transitionToCompletedSkipFeedback = async (data) => {
  return patchApiRequest(`/api/admin/assignments/${data.id}/to_completed_skip_feedback_state/`);
};

export const transitionToCompleted = async (data) => {
  return patchApiRequest(`/api/admin/assignments/${data.id}/to_completed_state/`, {
    release_ai_feedback: data.release_ai_feedback,
    release_ai_critical_thinking_feedback: data.release_ai_critical_thinking_feedback,
    release_ai_writing_feedback: data.release_ai_writing_feedback,
  });
};

export const publish = async (data) => {
  return postApiRequest(`/api/admin/assignments/${data.id}/publish/`);
};


export const getCourse = async (id) => {
  return getApiRequest(`/api/admin/courses/${id}/`);
};

export const getCourseDefaults = {
  cacheTime: 10000,
  staleTime: 5000,
};

export const useCourse = (id) => {
  return useQuery(["admin-course", id], () => getCourse(id), getCourseDefaults);
}

export const getSectionsForCourse = async (id) => {
  return getApiRequest(`/api/admin/sections/?course=${id}`);
};

export const getSectionsForCourseDefaults = {
  cacheTime: 10000,
  staleTime: 5000,
};

export const useSectionsForCourse = (id) => {
  return useQuery(["admin-course-sections", id], () => getSectionsForCourse(id), getSectionsForCourseDefaults);
}

export const getAssignmentsForCourse = async (id) => {
  return getApiRequest(`/api/admin/assignments/?section__course=${id}`);
};

export const useAssignmentsForCourse = (id) => {
  return useQuery(["admin-assignments-course", id], () => getAssignmentsForCourse(id), { cacheTime: 30000, staleTime: 10000 });
}

export const getAssignmentListForCourse = async (id) => {
  return getApiRequest(`/api/v2/admin/assignments/list/?course=${id}`);
};

export const useAssignmentListForCourse = (id) => {
  return useQuery(["admin-assignments-list-course", id], () => getAssignmentListForCourse(id), { cacheTime: 30000, staleTime: 10000 });
}

export const getAssignmentListForSection = async (id) => {
  return getApiRequest(`/api/v2/admin/assignments/list/?section=${id}`);
};

export const useAssignmentListForSection = (id) => {
  return useQuery(["admin-assignments-list-section", id], () => getAssignmentListForSection(id), { cacheTime: 30000, staleTime: 10000 });
}

export const getSubmissionsForCourse = async (id) => {
  return getApiRequest(`/api/admin/submissions/?assignment__section__course=${id}`);
};

export const useSubmissionsForCourse = (id) => {
  return useQuery(["admin-submissions-course", id], () => getSubmissionsForCourse(id), { cacheTime: 30000, staleTime: 10000 });
}

export const getSection = async (id) => {
  return getApiRequest(`/api/admin/sections/${id}/`);
};

export const useSection = (id) => {
  return useQuery(["admin-section", id], () => getSection(id), { cacheTime: 30000, staleTime: 10000 });
}

export const getMembersInSection = async (id) => {
  return getApiRequest(`/api/admin/sections/${id}/members/`);
}

export const useMembersInSection = (id) => {
  return useQuery(["admin-section-members", id], () => getMembersInSection(id), { cacheTime: 15 * 1000, staleTime: 2 * 1000 });
}

export const getAssignmentsInSection = async (id) => {
  return getApiRequest(`/api/admin/assignments/?section=${id}`);
}

export const useAssignmentsInSection = (id) => {
  return useQuery(["admin-section-assignments", id], () => getAssignmentsInSection(id), { cacheTime: 15 * 1000, staleTime: 2 * 1000 });
}

export const getSubmissionsInSection = async (id) => {
  return getApiRequest(`/api/admin/submissions/?assignment__section=${id}`);
}

export const useSubmissionsInSection = (id) => {
  return useQuery(["admin-section-submissions", id], () => getSubmissionsInSection(id), { cacheTime: 15 * 1000, staleTime: 2 * 1000 });
}

export const getCourseEmptySections = async (id) => {
  return getApiRequest(`/api/admin/courses/${id}/empty_sections/`);
};

export const createTeams = async (assignmentID, formData) => {
  return postApiRequest(`/api/admin/assignments/${assignmentID}/create_teams/`, formData);
};

export const getNoTeamMembers = async (assignmentID) => {
  return getApiRequest(`/api/admin/assignments/${assignmentID}/no_team_members/`);
};

export const addTeamMember = async (values) => {
  const { leagueID, sectionMemberID } = values
  console.log(`In Query LeagueID: ${leagueID} SectionMemberID: ${sectionMemberID}`)
  return postApiRequest(`/api/admin/leagues/${leagueID}/add_team_member/`, {
    section_member_id: sectionMemberID
  });
};

export const removeTeamMember = async (values) => {
  const { leagueID, sectionMemberID } = values
  console.log(`In Query LeagueID: ${leagueID} SectionMemberID: ${sectionMemberID}`)
  return postApiRequest(`/api/admin/leagues/${leagueID}/remove_team_member/`, {
    section_member_id: sectionMemberID
  });
};

export const randomizeTeams = async (assignmentID) => {
  return postApiRequest(`/api/admin/assignments/${assignmentID}/randomize_teams/`);
};

export const deleteTeam = async (id) => {
  return postApiRequest(`/api/admin/leagues/${id}/delete/`);
};

export const getSectionMembers = async (assignmentID) => {
  return getApiRequest(`/api/admin/assignments/${assignmentID}/sectionmembers/`);
};

export const getStudentResult = async (assignmentID, sectionMemberID) => {
  return getApiRequest(`/api/admin/assignment-results/${assignmentID}/${sectionMemberID}/`);
};

export const getStudentResultCompare = async (assignmentID, sectionMemberID) => {
  return getApiRequest(`/api/admin/assignment-results-compare/${assignmentID}/${sectionMemberID}/`);
};

export const getStudentReviewFeedback = async (assignmentID, sectionMemberID) => {
  return getApiRequest(`/api/admin/assignment-results-feedback/${assignmentID}/${sectionMemberID}/`);
};


export const getCourses = async () => {
  return getApiRequest('/api/admin/courses/?ordering=-created');
}

export const useCourses = () => {
  return useQuery(["admin-courses"], getCourses, { cacheTime: 15 * 1000, staleTime: 2 * 1000 });
}

export const getOralAssignment = async (id) => {
  return getApiRequest(`/api/v2/admin/oral/${id}/`);
}

export const useOralAssignment = (id) => {
  return useQuery(["admin-oral-assignment", id], () => getOralAssignment(id), { cacheTime: 15 * 1000, staleTime: 2 * 1000 });
}

export const updateOralAssignment = async (id, data) => {
  return postApiRequest(`/api/v2/admin/oral/${id}/`, data);
}

export const createOralAssignment = async (data) => {
  console.log("In query")
  console.dir(data)
  return postApiRequest(`/api/v2/admin/oral/`, data);
}

export const getOralQuestionTypes = async () => {
  return getApiRequest('/api/v2/admin/oral/question-types/');
}

export const useOralQuestionTypes = () => {
  return useQuery(["admin-oral-question-types"], getOralQuestionTypes, { cacheTime: 30 * 1000, staleTime: 10 * 1000 });
}

export const getOralSubmissionsForAssignment = async (id) => {
  return getApiRequest(`/api/v2/admin/oral/${id}/submissions/`);
}

export const useOralSubmissionsForAssignment = (id) => {
  return useQuery(["admin-oral-submissions", id], () => getOralSubmissionsForAssignment(id), { cacheTime: 30 * 1000, staleTime: 10 * 1000 });
}

export const getOralQuestionSummary = async (id) => {
  return getApiRequest(`/api/v2/admin/oral/${id}/summary/`);
}

export const useOralQuestionSummary = (id) => {
  return useQuery(["admin-oral-summary", id], () => getOralQuestionSummary(id), { cacheTime: 30 * 1000, staleTime: 10 * 1000 });
}

export const getOralSubmissionDetail = async (id, memberID) => {
  return getApiRequest(`/api/v2/admin/oral/submission/${id}/${memberID}/`);
}

export const useOralSubmissionDetail = (id, memberID) => {
  return useQuery(["admin-oral-submission-detail", id, memberID], () => getOralSubmissionDetail(id, memberID), { cacheTime: 30 * 1000, staleTime: 10 * 1000 });
}

export const getOralQuestionDetail = async (id) => {
  return getApiRequest(`/api/v2/admin/oral/question/${id}/`);
}

export const useOralQuestionDetail = (id) => {
  return useQuery(["admin-oral-question-detail", id], () => getOralQuestionDetail(id), { cacheTime: 30 * 1000, staleTime: 10 * 1000 });
}

export const createNewQuestion = async (id, data) => {
  return postApiRequest(`/api/v2/admin/oral/${id}/add-question/`, data);
}

export const updateQuestion = async (id, data) => {
  return postApiRequest(`/api/v2/admin/oral/question/${id}/`, data);
}

export const deleteQuestion = async (id) => {
  return postApiRequest(`/api/v2/admin/oral/question/delete/${id}/`);
}

export const getOralPracticeDetail = async (id, memberID) => {
  return getApiRequest(`/api/v2/admin/oral/practice/${id}/${memberID}/`);
}

export const useOralPracticeDetail = (id, memberID) => {
  return useQuery(["admin-oral-practice-detail", id, memberID], () => getOralPracticeDetail(id, memberID), { cacheTime: 30 * 1000, staleTime: 10 * 1000 });
}


export const getEmbeddedOralQuestions = (id) => {
  return getApiRequest(`/api/v2/admin/assignments/${id}/oral/questions/`);
}
export const useEmbeddedOralQuestions = (id) => {
  return useQuery(["admin-embedded-oral-questions", id], () => getEmbeddedOralQuestions(id), { cacheTime: 30 * 1000, staleTime: 10 * 1000 });
}
