import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import Switch from 'react-switch';

import StepSection from '../../../components/step-section/step-section';
import DateInput from '../../../components/date-input/date-input';

import styles from './assignment-bulk-create.module.scss';


const Step2 = (props) => {
  const {
    handleSubmit, setValue, register, unregister, formState: { errors }
  } = useForm();
  const { id } = useParams();

  const {
    sections, prepareStepFormData
  } = props;

  const [useMultipleDueDatesState, setUseMultipleDueDatesState] = useState(false);

  const [reviewScoreState, setReviewScoreState] = useState(true);
  const [reviewCommentState, setReviewCommentState] = useState(true);
  const [reviewFeedbackScoreState, setReviewFeedbackScoreState] = useState(true);
  const [reviewFeedbackCommentState, setReviewFeedbackCommentState] = useState(true);
  const onSubmit = (data) => {
    prepareStepFormData({
      dates: data,
      reviewScoreState,
      reviewCommentState,
      reviewFeedbackScoreState,
      reviewFeedbackCommentState
    });
  };

  const handleSwitchMultipleDueDates = () => {
    setUseMultipleDueDatesState((prevState) => !prevState);
  };

  const handleSwitchReviewScoreState = () => {
    setReviewScoreState((prevState) => !prevState);
  };

  const handleSwitchReviewCommentState = () => {
    setReviewCommentState((prevState) => !prevState);
  };

  const handleSwitchReviewFeedbackScoreState = () => {
    setReviewFeedbackScoreState((prevState) => !prevState);
  };

  const handleSwitchReviewFeedbackCommentState = () => {
    setReviewFeedbackCommentState((prevState) => !prevState);
  };

  const renderSectionsDueDates = () => {
    return (
      sections.map((item, index) => (
        <div className={styles.row} key={item.id}>
          <DateInput
            register={register}
            unregister={unregister}
            setValue={setValue}
            label={`Section ${item.name}`}
            name={`section_${item.id}`}
          />
        </div>
      ))
    );
  };

  return (
    <form
      className={styles.step}
      id="step-form"
      onSubmit={handleSubmit(onSubmit)}
    >
      <StepSection shadow>
        <div className={styles.row}>
          <div className={styles.switch}>
            <Switch
              onChange={handleSwitchReviewScoreState}
              checked={reviewScoreState}
              offColor="#d4d4d5"
              height={22}
              width={47}
              handleDiameter={19}
              onColor="#68d391"
              aria-label="Use score for Review"
            />
            <span>
              Use score for Review
            </span>
          </div>
        </div>
        <div className={styles.row}>
          <div className={styles.switch}>
            <Switch
              onChange={handleSwitchReviewCommentState}
              checked={reviewCommentState}
              offColor="#d4d4d5"
              height={22}
              width={47}
              handleDiameter={19}
              onColor="#68d391"
              aria-label="Use Comments for Review"
            />
            <span>
              Use Comments for Review
            </span>
          </div>
        </div>
        <div className={styles.row}>
          <div className={styles.switch}>
            <Switch
              onChange={handleSwitchReviewFeedbackScoreState}
              checked={reviewFeedbackScoreState}
              offColor="#d4d4d5"
              height={22}
              width={47}
              handleDiameter={19}
              onColor="#68d391"
              aria-label="Use Score for Review Feedback"
            />
            <span>
              Use Score for Review Feedback
            </span>
          </div>
        </div>
        <div className={styles.row}>
          <div className={styles.switch}>
            <Switch
              onChange={handleSwitchReviewFeedbackCommentState}
              checked={reviewFeedbackCommentState}
              offColor="#d4d4d5"
              height={22}
              width={47}
              handleDiameter={19}
              onColor="#68d391"
              aria-label="Use Comments for Review Feedback"
            />
            <span>
              Use Comments for Review Feedback
            </span>
          </div>
        </div>
      </StepSection>
      <StepSection shadow>
        <div className={styles.row}>
          <div className={styles.switch}>
            <Switch
              onChange={handleSwitchMultipleDueDates}
              checked={useMultipleDueDatesState}
              offColor="#d4d4d5"
              height={22}
              width={47}
              handleDiameter={19}
              onColor="#68d391"
              aria-label="Use different due Dates"
            />
            <span>
              Use different Due Dates for each section
            </span>
          </div>
        </div>
        {!useMultipleDueDatesState && (
          <div className={styles.row}>
            <DateInput
              register={register}
              unregister={unregister}
              setValue={setValue}
              label="Due Date"
              name="general_due_date"
            />
          </div>
        )}
        {useMultipleDueDatesState && (
          renderSectionsDueDates()
        )}
      </StepSection>
    </form>
  );
};

Step2.propTypes = {
  sections: PropTypes.arrayOf(PropTypes.object).isRequired,
};

Step2.defaultProps = {
};

export default Step2;
