import React from 'react';

import styles from './spinner.module.scss';


const Spinner = (props) => {
  return (
    <div className={styles.container}>
      <div className={styles.spinnerContainer}>
        <div className={styles.spinner} />
      </div>
    </div>
  );
};

export default Spinner;
