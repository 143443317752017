import { connect } from 'react-redux';

import { createOrUpdateReview, resetReviewCreated } from '../../../actions/reviews';
import { formApiAction } from '../../../actions/api';

import ReviewCreate from './review-create';

const mapStateToProps = (state) => ({
  reviewCreated: state.reviews.reviewCreated,
});

const mapDispatchToProps = (dispatch) => {
  const formCreateOrUpdateReview = formApiAction(createOrUpdateReview);
  return {
    resetReviewCreated: () => dispatch(resetReviewCreated()),
    createOrUpdateReview: (formData, setError) => dispatch(
      formCreateOrUpdateReview(formData, setError)
    ),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ReviewCreate);
