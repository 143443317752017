import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import LeaveWarning from '../../../components/leave-warning/leave-warning';
import Editor from '../../../components/editor/editor';
import HelpLabel from '../../../components/help-label';

import styles from './assignment-edit.module.scss';

const Step2 = (props) => {
  const {
    setAssignmentDraft, goToNextStep, assignmentDraft, setCanClose, help
  } = props;
  const { handleSubmit, formState: { isDirty } } = useForm();
  const [showDirtyMessage, setShowDirtyMessage] = useState(false);
  const [editorDraftState, setEditorDraftState] = useState({
    body: assignmentDraft.body,
    plain_text: assignmentDraft.body_plaintext,
  });

  const onSubmit = () => {
    setAssignmentDraft({
      ...assignmentDraft,
      body: editorDraftState.body,
      body_plaintext: editorDraftState.plain_text,
    });
    goToNextStep();
  };

  // Track react-hook-form and editor dirty state
  useEffect(() => {
    let editorDirty = assignmentDraft.description != editorDraftState.body;
    if (isDirty || editorDirty) {
      setShowDirtyMessage(true);
      setCanClose(false);
    } else {
      setShowDirtyMessage(false);
      setCanClose(true);
    }
  }, [isDirty, editorDraftState]);

  const handleScroll = (e) => {
    const node = document.getElementsByClassName('rdw-editor-toolbar')[0];
    if (e.target.scrollTop > 162) {
      node.classList.add('stickyToolbar');
    } else {
      node.classList.remove('stickyToolbar');
    }
  };

  return (
    <React.Fragment>
      <LeaveWarning when={showDirtyMessage} />
      <form
        className={styles.step}
        id="step-form"
        onSubmit={handleSubmit(onSubmit)}
        onScroll={handleScroll}
      >
        <div className="m-6 bg-blue-100 text-lg border border-blue-400 p-6">
          <HelpLabel label="Assignment" name="step_2_assignment" help={help} />
          <p>
            <strong>Below is text that students will see.</strong> This is where you enter your Assignment, or direct
            students to find the assignment in the LMS. For students, this is Stage 1, Submit. Students may upload attachments and/or type directly into the text box provided for them. You
            will want to include how their Submission will be evaluated by classmates in Stage 2,
            Review. (For more information on what to include on this page, including your choices
            about anonymity, please click on the i icon above, next to the word Assignment).</p>
          <p className="my-2"> Select "Save &amp; Continue" to advance to
            the next tab. For all tabs, select "Save &amp; Continue" to advance.
          </p>
        </div>
        <Editor
          body={assignmentDraft.body}
          handleBodyUpdate={setEditorDraftState}
        />
      </form>
    </React.Fragment>
  );
};

Step2.propTypes = {
  setAssignmentDraft: PropTypes.func.isRequired,
  goToNextStep: PropTypes.func.isRequired,
  assignmentDraft: PropTypes.shape({}).isRequired,
};

Step2.defaultProps = {
};

export default Step2;
