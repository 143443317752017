import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from './step-section.module.scss';


const StepSection = (props) => {
  const {
    children, className, fullWidth, bgGray, noPadding, shadow, transparentContainer
  } = props;

  return (
    <section className={classNames(
      styles.wrapper, {
      [styles.bgGray]: bgGray,
    },
      className,
    )}
    >
      <div className={classNames(
        styles.container, {
        [styles.fullWidth]: fullWidth,
        [styles.noPadding]: noPadding,
        [styles.shadow]: shadow,
        [styles.transparentContainer]: transparentContainer,
      },
        className,
      )}
      >
        {children}
      </div>
    </section>
  );
};

StepSection.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
  className: PropTypes.oneOfType([
    PropTypes.shape({}),
    PropTypes.string
  ]),
  fullWidth: PropTypes.bool,
  bgGray: PropTypes.bool,
  noPadding: PropTypes.bool,
  shadow: PropTypes.bool,
  transparentContainer: PropTypes.bool,
};

StepSection.defaultProps = {
  children: [],
  className: null,
  fullWidth: false,
  bgGray: false,
  noPadding: false,
  shadow: true,
  transparentContainer: false,
};

export default StepSection;
