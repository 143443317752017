import React from 'react';
import { Link, useParams } from 'react-router-dom';

import UserListItem from '../../../components/user-list-item/user-list-item';
import { useUser } from '../../../queries/user';
import { useMembersInSection } from '../../../queries/admin-queries';

import styles from './section-detail.module.scss';

const SectionDetailParticipants = (props) => {
  const { id } = useParams();
  const user = useUser();
  const members = useMembersInSection(id);

  if (user.isLoading || members.isLoading) {
    return (<div>Loading...</div>);
  }

  return (
    <React.Fragment>
      {!user.data.is_lti &&
        <div className={styles.actions}>
          <div className="container">
            <Link
              className={styles.smallButton}
              to={{ pathname: `/sections/${id}/participant/create`, state: { modal: true } }}
            >
              Add participant
            </Link>
            <Link
              className={styles.smallButton}
              to={{ pathname: `/sections/${id}/participants/create`, state: { modal: true } }}
            >
              Import Participants via CSV
            </Link>
          </div>
        </div>
      }
      <section className={styles.section}>
        <div className="container">
          {members.data.map((member, index) => (
            <UserListItem key={index} user={member} />
          ))}
        </div>
      </section>
    </React.Fragment>
  );
};

export default SectionDetailParticipants;
