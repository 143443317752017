import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  EditorState, ContentState, convertFromHTML, convertToRaw
} from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import draftToHtml from 'draftjs-to-html';
import classNames from 'classnames';

import styles from './editor.module.scss';

const WYSIWYGEditor = (props) => {
  const {
    fullViewPort, body, handleBodyUpdate, readOnly, toolbarHidden, className
  } = props;

  const [editorState, setEditorState] = useState(
    EditorState.createWithContent(
      ContentState.createFromBlockArray(
        convertFromHTML(body)
      )
    ),
  );

  useEffect(() => {
    if (body) {
      setEditorState(
        EditorState.createWithContent(
          ContentState.createFromBlockArray(
            convertFromHTML(body)
          )
        ),
      );
    }
  }, [body]);

  const onEditorStateChange = (state) => {
    setEditorState(state);
    if (!readOnly) {
      const currentContent = state.getCurrentContent();
      const rawContentState = convertToRaw(currentContent);
      const plainText = currentContent.getPlainText();
      const markup = draftToHtml(rawContentState);
      handleBodyUpdate((prevState) => ({
        ...prevState,
        body: markup,
        plain_text: plainText,
      }));
    }
  };

  return (
    <React.Fragment>
      <Editor
        editorState={editorState}
        toolbarClassName={styles.toolbar}
        wrapperClassName={classNames(
          styles.editorWrapper, {
          [styles.fullViewPort]: fullViewPort,
        },
          className
        )}
        readOnly={readOnly}
        toolbarHidden={toolbarHidden}
        editorClassName={styles.editor}
        onEditorStateChange={onEditorStateChange}
        toolbar={{
          options: ['inline', 'blockType', 'list', 'textAlign', 'link', 'remove', 'history'],
          inline: { inDropdown: true },
          list: { inDropdown: true },
          textAlign: { inDropdown: true },
          link: { inDropdown: true },
          history: { inDropdown: true },
        }}
        toolbarStyle={{
          position: 'sticky',
          top: 0,
          zIndex: 10,
        }}
      />
    </React.Fragment>
  );
};

WYSIWYGEditor.propTypes = {
  fullViewPort: PropTypes.bool,
  readOnly: PropTypes.bool,
  toolbarHidden: PropTypes.bool,
  body: PropTypes.string,
  handleBodyUpdate: PropTypes.func
};

WYSIWYGEditor.defaultProps = {
  fullViewPort: false,
  readOnly: false,
  toolbarHidden: false,
  body: '',
  handleBodyUpdate: () => { },
};

export default WYSIWYGEditor;
