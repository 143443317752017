import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from './list.module.scss';


const Grid = (props) => {
  const {
    children, fullWidth, className
  } = props;

  return (
    <section
      className={classNames(
        'container',
        styles.container, {
          [styles.fullWidth]: fullWidth,
        },
        className,
      )}
    >
      <div className={styles.wrapper}>
        {children}
      </div>
    </section>
  );
};

Grid.propTypes = {
  children: PropTypes.node.isRequired,
  fullWidth: PropTypes.bool,
};

Grid.defaultProps = {
  fullWidth: false,
};

export default Grid;
