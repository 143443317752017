// same configuration you would create for the Rollbar.js SDK
const token = '9694fa81428c4ac6a381b6a4fe2c6bd5'

const getRollbarConfig = () => {
  const host = window.location.host;
  if (host == "dev.peerexchange.io") {
    return {
      accessToken: token,
      environment: 'development',
      captureUncaught: true,
      captureUnhandledRejections: true,
      payload: {
        server: {
          root: "http://dev.peerexchange.io/",
          branch: "main"
        },
        person: {
          id: null,
          email: null,
        }
      }
    }
  }
  if (host === "dashboard.peerexchange.io") {
    return {
      accessToken: token,
      environment: 'production',
      captureUncaught: true,
      captureUnhandledRejections: true,
      payload: {
        server: {
          root: "http://dev.peerexchange.io/",
          branch: "main"
        },
        person: {
          id: null,
          email: null,
        }
      }
    }
  }
  return {
    accessToken: token,
    environment: 'local',
    captureUncaught: true,
    captureUnhandledRejections: true,
    payload: {
      server: {
        root: "http://localhost:8000/",
        branch: "main"
      },
      person: {
        id: null,
        email: null,
      }
    }
  }
}

export default getRollbarConfig
