import { connect } from 'react-redux';

import {
  createSectionParticipants, resetParticipantsCreated,
} from '../../../actions/sections';
import { formApiAction } from '../../../actions/api';
import ParticipantsCreate from './participants-create';

const mapStateToProps = (state) => ({
  participantsCreated: state.sections.participantsCreated,
});

const mapDispatchToProps = (dispatch) => {
  const formcCeateSectionParticipants = formApiAction(createSectionParticipants);
  return {
    createSectionParticipants: (formData, setError) => dispatch(
      formcCeateSectionParticipants(formData, setError)
    ),
    resetParticipantsCreated: () => dispatch(resetParticipantsCreated()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ParticipantsCreate);
