import { connect } from 'react-redux';

import {
  createSingleSectionParticipant, resetParticipantsCreated,
} from '../../../actions/sections';
import { formApiAction } from '../../../actions/api';
import ParticipantCreate from './participant-create';

const mapStateToProps = (state) => ({
  participantsCreated: state.sections.participantsCreated,
});

const mapDispatchToProps = (dispatch) => {
  const formcCeateSingleSectionParticipants = formApiAction(createSingleSectionParticipant);
  return {
    createSingleSectionParticipant: (formData, setError) => dispatch(
      formcCeateSingleSectionParticipants(formData, setError)
    ),
    resetParticipantsCreated: () => dispatch(resetParticipantsCreated()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ParticipantCreate);
