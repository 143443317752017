import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import { useParams, useHistory } from 'react-router-dom';
import { useForm, useFormContext, FormProvider, Controller } from 'react-hook-form';
import 'react-datepicker/dist/react-datepicker.css';
import { useMutation, useQueryClient } from 'react-query';
import { useUser } from '../../../queries/user';
import { useOralAssignment, useOralQuestionTypes, createNewQuestion } from '../../../queries/admin-queries';
import Loading from '../../../components/loading/loading';
import Select from 'react-select'
import QuestionCriteria from './question-criteria';

const buttonClasses = classNames('rounded py-2 px-4 text-lg text-white bg-blue-500 hover:bg-blue-700 w-full cursor-pointer my-1');
const greenButtonClasses = classNames('rounded py-2 px-4 text-lg text-white bg-green-500 hover:bg-green-700 w-full cursor-pointer my-1');

const CurrentState = ({ state }) => {
  // Handle colors
  const classes = classNames('rounded py-2 px-4 text-lg text-white bg-gray-800 uppercase', {
    'bg-gray-400': state === 'pending',
    'bg-green-500': state === 'active',
    'bg-red-500': state === 'completed',
  });

  return (
    <span className={classes}>{state}</span>
  );
};

const Input = ({ label, name, type = 'text', placeholder = undefined, value = undefined }) => {
  const { register, setValue, formState: { errors } } = useFormContext();
  const errorMessage = errors[name]?.message?.toString()

  useEffect(() => {
    if (value) {
      setValue(name, value)
    }
  }, [value]);

  return (
    <div className="w-full">
      <label htmlFor={`id_${name}`} className="block text-sm/6 font-semibold text-gray-900">
        {label}
      </label>
      <div className="mt-6 w-full">
        <input
          id={`id_${name}`}
          name={name}
          type={type}
          placeholder={placeholder}
          className="block w-full bg-white py-1 px-2 text-base text-slate-900 outline outline-1 -outline-offset-1 outline-blue-300 placeholder:text-blue-300 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-blue-600 sm:pr-9 sm:text-sm/6"
          {...register(name)}
        />
      </div>
      {errorMessage && (
        <p id="email-error" className="mt-2 text-sm text-red-600">
          {errorMessage}
        </p>
      )}
    </div>
  )
}

const TextInput = ({ label, name, placeholder = undefined, value = undefined, autoFocus = false, rows = 3 }) => {
  const { register, setValue, formState: { errors } } = useFormContext();
  const errorMessage = errors[name]?.message?.toString()

  useEffect(() => {
    if (value) {
      setValue(name, value)
    }
  }, [value]);

  return (
    <div className="w-full">
      <label htmlFor={`id_${name}`} className="block text-sm/6 font-semibold text-gray-900">
        {label}
      </label>
      <div className="mt-6 w-full">
        <textarea
          id={`id_${name}`}
          name={name}
          placeholder={placeholder}
          className="block w-full bg-white py-1 px-2 text-base text-slate-900 outline outline-1 -outline-offset-1 outline-blue-300 placeholder:text-blue-300 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-blue-600 sm:pr-9 sm:text-sm/6"
          autoFocus={autoFocus}
          rows={rows}
          {...register(name)}
        />
      </div>
      {errorMessage && (
        <p id="email-error" className="mt-2 text-sm text-red-600">
          {errorMessage}
        </p>
      )}
    </div>
  )
}


const OralAddQuestion = (props) => {
  const { id } = useParams();
  const history = useHistory();
  const user = useUser();
  const assignment = useOralAssignment(id);
  const queryClient = useQueryClient();

  const [criteria, setCriteria] = useState([]);
  const form = useForm();
  const mutation = useMutation('new-oral-question', (data) => createNewQuestion(id, data));
  const questionTypes = useOralQuestionTypes();

  if (user.isLoading || assignment.isLoading || questionTypes.isLoading) {
    return <Loading />;
  }

  // Filter out "needs_reviews" question types as there isn't a review process
  // in standalone Oral assignments
  const questionOptions = questionTypes.data.filter(
    (type) => !type.needs_reviews
  ).map(
    (type) => ({ value: type.id, label: type.name })
  );

  const onSubmit = (data) => {
    console.log("Adding question...")
    data.criteria = criteria
    console.dir(data)
    mutation.mutate(data, {
      onSuccess: (data, values) => {
        queryClient.invalidateQueries(["admin-oral-assignment"]);
        history.push(`/oral/assignments/${id}`);
      },
      onError: (error, variables, context) => {
        console.log(error.response, variables, context);
      }
    });
  }

  const buttonGroupClasses = classNames("flex items-center float-right my-6", {
  })

  return (
    <React.Fragment>
      <div className="flex flex-row items-center">
        <h1 className="my-4 text-2xl font-bold flex-grow">Add Question - {assignment.data.title}</h1>
        <span className="flex-initial mx-2">
          <CurrentState state={assignment.data.state} />
        </span>
      </div>
      <hr className="border-1 border-solid border-black my-4" />
      {assignment.data.state === 'active' && (
        <div className="my-6 bg-yellow-200 border border-yellow-500 p-2 ">
          <strong>WARNING:</strong> Adding a question while your assignment is active
          may result in students who have finished not seeing it.
        </div>
      )}
      <div className="flex mt-8 bg-blue-200 border border-blue-400 px-4 w-full">
        <FormProvider {...form}>
          <form className="w-full" onSubmit={form.handleSubmit(onSubmit)}>
            <div className="my-6">
              <div className="w-full">
                <label htmlFor={`id_question_type`} className="my-6 block text-sm/6 font-semibold text-gray-900">
                  Question Type
                </label>
                <Controller
                  control={form.control}
                  name="question_type_id"
                  render={({ field }) => (
                    <Select
                      inputRef={field.ref}
                      options={questionOptions}
                      value={questionOptions.find(c => c.value === field.value)}
                      onChange={value => field.onChange(value.value)}
                    />
                  )}
                />
              </div>
            </div>
            <div className="my-6">
              <TextInput
                label="Question"
                name="question_text"
                autoFocus={true}
                placeholder="Enter your question here..."
                rows={5}
              />
            </div>
            <div className="my-6">
              <Input
                label="Academic Context"
                name="academic_context"
                placeholder="Let ChatGPT know the general academic context"
              />
            </div>
            <div className="my-6">
              <Input
                label="Topic / Concept"
                name="topic_concept"
                placeholder="Let ChatGPT know the topic or concept around this question"
              />
            </div>
            <div className="my-6">
              <TextInput
                label="What would be useful for ChatGPT to consider when evaluating the student's transcript?"
                name="ai_context"
              />
            </div>

            <div className="my-6">
              <QuestionCriteria
                criteria={criteria}
                setCriteria={setCriteria}
              />
            </div>
            <div className="my-6">
              <div className={buttonGroupClasses}>
                <button className="mr-8" onClick={() => history.push(`/oral/assignments/${id}`)}>Cancel</button>
                <button className={buttonClasses} type="submit">Save Changes</button>
              </div>
            </div>
          </form>
        </FormProvider>

      </div>
    </React.Fragment>
  );
};

export default OralAddQuestion;
