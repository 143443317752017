import React from 'react';
import { Link, useParams } from 'react-router-dom';

import AssignmentListItem from '../../../components/assignment-list-item/assignment-list-item';
import List from '../../../components/list/list';

import styles from './section-detail.module.scss';

import {
  useAssignmentListForSection
} from '../../../queries/admin-queries';

const SectionDetailAssignment = (props) => {
  const { history } = props;
  const { id } = useParams();
  const assignments = useAssignmentListForSection(id);

  if (assignments.isLoading) return (<div>Loading...</div>);

  return (
    <React.Fragment>
      <section className={styles.section}>
        <List>
          {assignments.data.length === 0 && (
            <h3>This Section currently has no assignments</h3>
          )}
          {assignments.data.map((assignment) => (
            <AssignmentListItem
              history={history}
              id={assignment.id}
              title={assignment.title}
              dueDate={assignment.due_date}
              imageURL={assignment.thumbnail}
              state={assignment.state}
              paused={assignment.paused}
              key={assignment.id}
              type={assignment.type}
            />
          ))}
        </List>
      </section>
    </React.Fragment>
  );
};


export default SectionDetailAssignment;
