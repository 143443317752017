import React from 'react';
import classNames from 'classnames';
import {
  Switch, NavLink, useParams
} from 'react-router-dom';

import ProtectedRoute from '../../../routes/protected-route';
import Navigation from '../../../components/navigation/navigation';
import ScrollArea from '../../../components/scroll-area/scroll-area';
import Footer from '../../../components/footer/footer';
import Spinner from '../../../components/spinner/spinner';

import SectionDetailOverview from './section-detail-overview';
import SectionDetailMembers from './section-detail-participants';
import SectionDetailAssignments from './section-detail-assignments';
import SectionDetailSubmissions from './section-detail-submissions';

import {
  useSection, useMembersInSection, useAssignmentsInSection,
  useSubmissionsInSection, useAssignmentListForSection
} from '../../../queries/admin-queries'

import styles from './section-detail.module.scss';

const SectionDetail = (props) => {
  const { match } = props;
  const { path } = match;
  const { id } = useParams();

  const section = useSection(id);

  // Just to pre-load for faster sub-pages
  const members = useMembersInSection(id);
  const assignments = useAssignmentListForSection(id);
  const submissions = useSubmissionsInSection(id);

  if (section.isLoading) {
    return <Spinner />;
  }

  return (
    <div className={styles.container}>
      <Navigation
        backURL={`/courses/${section.data.course}`}
        title={section.data.course_name}
        subtitle={section.data.name}
        hasBackButton
      />
      <ScrollArea>
        <div className={styles.tabsContainer}>
          <div
            className={classNames(
              'container',
              styles.tabs,
            )}
          >
            <NavLink
              exact
              className={styles.tabLink}
              activeClassName={styles.tabLinkActive}
              to={`/sections/${id}`}
            >
              Section Overview
            </NavLink>
            <NavLink
              exact
              className={styles.tabLink}
              activeClassName={styles.tabLinkActive}
              to={`/sections/${id}/participants`}
            >
              Participants
            </NavLink>
            <NavLink
              exact
              className={styles.tabLink}
              activeClassName={styles.tabLinkActive}
              to={`/sections/${id}/assignments`}
            >
              Assignments
            </NavLink>
            <NavLink
              exact
              className={styles.tabLink}
              activeClassName={styles.tabLinkActive}
              to={`/sections/${id}/submissions`}
            >
              Submissions
            </NavLink>
          </div>
        </div>
        <Switch>
          <ProtectedRoute exact path={`${path}`} component={SectionDetailOverview} />
          <ProtectedRoute path={`${path}/participants`} component={SectionDetailMembers} />
          <ProtectedRoute path={`${path}/assignments`} component={SectionDetailAssignments} />
          <ProtectedRoute path={`${path}/submissions`} component={SectionDetailSubmissions} />
        </Switch>
        <Footer />
      </ScrollArea>
    </div>
  );
};

export default SectionDetail;
