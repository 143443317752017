import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query-devtools';

import store from './store/store';
import App from './app';
import ErrorBoundry from './common/error-boundry';
import './index.css';
import './scss/app.module.scss';

const queryClient = new QueryClient();

ReactDOM.render(
  <Provider store={store}>
    <QueryClientProvider client={queryClient}>
      <ErrorBoundry>
        <App />
      </ErrorBoundry>
      <ReactQueryDevtools initialIsOpen />
    </QueryClientProvider>
  </Provider>,
  document.getElementById('app-container')
);
