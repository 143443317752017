import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Rating from 'react-rating';

import RatingStar from '../../assets/icons/rating-star.svg';
import RatingStarOutline from '../../assets/icons/rating-star-alternate.svg';

import styles from './rating.module.scss';


const RatingBox = (props) => {
  const {
    fixed, className, initialRating, onRatingChange, readonly, name, maxRating
  } = props;


  const handleChange = (e) => {
    onRatingChange(e, name);
  };

  return (
    <div className={classNames(
      styles.container, {
      [styles.fixed]: fixed,
    },
      className,
    )}
    >
      <Rating
        readonly={readonly}
        initialRating={initialRating}
        onChange={handleChange}
        stop={maxRating}
        emptySymbol={<RatingStarOutline href="#icon-star-empty" className={styles.icon} />}
        fullSymbol={<RatingStar href="#icon-star-full" className={styles.iconFull} />}
      />
    </div>
  );
};

RatingBox.propTypes = {
  name: PropTypes.string.isRequired,
  initialRating: PropTypes.number,
  onRatingChange: PropTypes.func,
  readonly: PropTypes.bool,
  maxRating: PropTypes.number,
};

RatingBox.defaultProps = {
  initialRating: 0,
  onRatingChange: () => { },
  readonly: false,
  maxRating: 5,
};

export default RatingBox;
