import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Checkmark from '../../../components/checkmark/checkmark';
import X from '../../../components/x/x';
import Avatar from '../../../components/avatar/avatar';
import { reviewFeedbacksSelector } from '../../../selectors/review-feedbacks';

import styles from './participant-detail.module.scss';

const Feedback = (props) => {
  const { feedback, index } = props;

  const history = useHistory();

  const handleClick = () => {
    history.push(`/feedbacks/${feedback.id}`);
  };

  return (
    <tr key={index} onClick={handleClick} className={styles.tableHover}>
      <td>
        <div className={styles.author}>
          <div className={styles.authorAvatar}>
            <Avatar
              name={feedback.author.display_name}
            />
          </div>
          <div className={styles.authorInfo}>
            <div className={styles.authorName}>{feedback.author.display_name}</div>
            <div className={styles.authorEmail}>{feedback.author.email}</div>
          </div>
        </div>
      </td>
      <td className={styles.feedbackTitle}>{feedback.review_author.display_name}</td>
      <td>{feedback.is_complete ? <Checkmark /> : <X />}</td>
      <td>
        <Link
          to={`/feedbacks/${feedback.id}`}
        >
          View
        </Link>
      </td>
    </tr>
  );
}

const ParticipantDetailFeedbacks = (props) => {
  const feedbacks = reviewFeedbacksSelector(
    useSelector((state) => state.reviewFeedbacks.reviewFeedbacks)
  );

  if (feedbacks.length === 0) {
    return (
      <p>There are currently no feedbacks</p>
    );
  }

  return (
    <table>
      <thead>
        <tr>
          <th>
            By
          </th>
          <th>
            Reviewed
          </th>
          <th>
            Completed
          </th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        {feedbacks.map((feedback, index) => (
          <Feedback key={feedback.id} feedback={feedback} index={index} />
        ))}
      </tbody>
    </table>
  );
};

ParticipantDetailFeedbacks.propTypes = {
};


export default ParticipantDetailFeedbacks;
