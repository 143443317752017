import React from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';

import styles from './section-list-item.module.scss';


const SectionListItem = (props) => {
  const {
    title, subtitle, id, studentCount,
  } = props;

  const history = useHistory();

  const handleClick = () => {
    history.push(`/sections/${id}`);
  };

  return (
    <div className={styles.container}>

      <div className={styles.item}>
        <div
          className={styles.content}
          onClick={handleClick}
        >
          <div className={styles.title}>{title}</div>
          <div className={styles.subtitle}>{subtitle}</div>
        </div>

        <div className={styles.stats}>
          <div className={styles.stat}>
            <span className={styles.statNumber}>
              {studentCount}
            </span>
            <span className={styles.statTitle}>
              Participants
            </span>
          </div>
        </div>

      </div>
    </div>
  );
};

SectionListItem.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  id: PropTypes.string.isRequired,
  studentCount: PropTypes.number,
};

SectionListItem.defaultProps = {
  title: '',
  subtitle: '',
  studentCount: 0,
};

export default SectionListItem;
