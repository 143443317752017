import React from 'react';
import { BrowserRouter, Route } from 'react-router-dom';
import { Provider, ErrorBoundary } from '@rollbar/react';
import getRollbarConfig from './common/rollbarSettings';
import { useUser } from './queries/user';
import Routes from './routes/root';


const App = (props) => {
  // Load user info
  const info = useUser();

  // Get base config and add person tracking if user is loaded
  const config = getRollbarConfig();
  if (info.isSuccess && info.data) {
    config.person = {
      id: info.data.id,
      email: info.data.email
    };
  }

  return (
    <BrowserRouter>
      <Provider config={config}>
        <ErrorBoundary>
          <Route component={Routes} />
        </ErrorBoundary>
      </Provider>
    </BrowserRouter>
  );
}

export default App;
