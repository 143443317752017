import React from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { useMutation } from 'react-query';

import { useHistory } from 'react-router-dom';

import Navigation from '../../../components/navigation/navigation';
import ScrollArea from '../../../components/scroll-area/scroll-area';
import Input from '../../../components/input/input';
import Button from '../../../components/button/button';

import { useUser, changePassword } from '../../../queries/user';

import styles from './settings.module.scss';


const Settings = (props) => {
  const history = useHistory();
  const user = useUser();

  const mutation = useMutation('user-change-password', (data) => changePassword(data));

  const {
    register, handleSubmit, setError, isSubmitting, formState: { errors }
  } = useForm();

  const onSubmit = (data) => {
    const formData = data;
    formData.org = user.data.email;
    mutation.mutate(formData, {
      onSuccess: (data, values) => {
        toast('Password successfully changed', { containerId: 'RIGHT' });
      },
      onError: (error, variables, context) => {
        console.log(error.response, variables, context);
        toast('Failed to change password', { containerId: 'RIGHT' });
      }
    });
  };

  return (
    <div className={styles.container}>
      <Navigation
        history={history}
        backURL="/dashboard"
        title="Settings"
        hasBackButton
      />
      <ScrollArea>
        <div className={styles.content}>
          <h3 className={styles.title}>Account Settings</h3>
          <div className={styles.row}>
            <div className={styles.left}>
              <p>Change Password</p>
            </div>
            <div className={styles.right}>
              <form onSubmit={handleSubmit(onSubmit)} >
                <div className={styles.input}>
                  <Input
                    label="New Password"
                    name="new_password1"
                    error={errors.new_password1}
                    placeholder="New Password"
                    type="password"
                    {...register('new_password1', { required: true })}
                  />
                </div>
                <div className={styles.input}>
                  <Input
                    label="Repeat Password"
                    name="new_password2"
                    error={errors.new_password2}
                    placeholder="Repeat New Password"
                    type="password"
                    {...register('new_password2', { required: true })}
                  />
                </div>
                <Button
                  type="submit"
                  disabled={isSubmitting}
                >
                  Update Password
                </Button>
              </form>
            </div>
          </div>
        </div>
      </ScrollArea>
    </div>
  );
};

export default Settings;
