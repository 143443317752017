import React, { useEffect, useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { useTransition, animated } from 'react-spring';
import { shortDate } from '../../common/utils';
import { stateText } from '../../common/state';

import styles from './assignment-list-item.module.scss';

const AssignmentListItem = (props) => {
  const {
    title, dueDate, id, state, paused, active, type
  } = props;

  const node = useRef();
  const [open, setOpen] = useState(false);

  const handleClickOutside = (e) => {
    if (node.current.contains(e.target)) {
      return;
    }
    setOpen(false);
  };

  useEffect(() => {
    if (open) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [open]);


  let stateTextDisplay = stateText(state, paused, active);
  let dueDateDisplay = `Due ${shortDate(dueDate)}`;
  let url = "assignments";
  let teamAssignment = null;

  if (type == 'team') {
    teamAssignment = (
      <div className={styles.teamDisplay}>
        Team Assignment
      </div>
    );
  }
  if (type == 'oral') {
    teamAssignment = (
      <div className={styles.teamDisplay}>
        Oral Assignment
      </div>
    );
    stateTextDisplay = <span className="capitalize">{state}</span>
    url = "oral/assignments"
  }

  return (
    <Link
      className={styles.container}
      to={`/${url}/${id}`}
    >
      <div className={styles.item}>
        <div className={styles.content}>
          <div className={styles.title}>{title || 'Unnamed Assignment'}</div>
          <div className={styles.subtitle}>
            {active && dueDateDisplay}
          </div>
          {state && (
            <div
              className={classNames(
                styles.state, {
                [styles.submission]: state === 'submission',
                [styles.review]: state === 'review',
                [styles.reviewFeedback]: state === 'review_feedback',
                [styles.completed]: state === 'completed',
                [styles.unpublished]: active === false,
              },
              )}
            >
              {stateTextDisplay}
            </div>
          )}
          {teamAssignment}
        </div>
      </div>
    </Link>
  );
};


export default AssignmentListItem;
