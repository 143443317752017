/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState } from 'react';

import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import { useTransition, animated } from 'react-spring';
import classNames from 'classnames';
import { useQueryClient } from 'react-query';
import CloseIcon from '../../assets/icons/close.svg';
import StackIcon from '../../assets/icons/file-stack.svg';
import SubmissionIcon from '../../assets/icons/submission.svg';
import AssignmentIcon from '../../assets/icons/assignment.svg';
import ReviewIcon from '../../assets/icons/review.svg';
import styles from './assignment-navigation.module.scss';

const AssignmentNavigation = (props) => {
  const {
    title, subtitle, setPreviewOpen, previewOpen,
    showAssignmentButton, showSubmissionButton, showReviewButton,
    isDemo
  } = props;

  const history = useHistory();
  const [open, setOpen] = useState(false);

  const transitions = useTransition(open, null, {
    from: { position: 'absolute', opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
  });

  const queryClient = useQueryClient();

  const handleBack = () => {
    queryClient.refetchQueries(['todo-list']);
    history.goBack();
  };

  const handleToggleMenu = () => {
    ReactTooltip.rebuild();
    setOpen(!open);
  };

  const handleMouseLeave = () => {
    // setOpen(false);
  };

  const handleTogglePreview = (previewType) => {
    if (previewOpen === previewType) {
      setPreviewOpen(false);
    } else {
      setPreviewOpen(previewType);
    }
  };

  let closeText = <CloseIcon className={styles.iconClose} />;

  if (isDemo) {
    closeText = 'Close'
  }

  return (
    <nav className={styles.container}>
      <div
        className={classNames(styles.left, 'assignment-navigation')}
        onClick={() => handleToggleMenu()}
        data-tip="Show related docs"
      >
        <StackIcon
          className={styles.iconToggle}
        />
      </div>
      {transitions.map(({ item, key, props }) => item && (
        <animated.div
          key={key}
          style={props}
          className={styles.dropdown}
          onMouseLeave={handleMouseLeave}
        >
          {showAssignmentButton && (
            <div
              className={classNames(
                styles.dropdownItem, {
                [styles.dropdownItemActive]: previewOpen === 'assignment',
              }
              )}
              onClick={() => handleTogglePreview('assignment')}
              data-tip="Show Assignment"
            >
              <AssignmentIcon
                className={styles.dropdownIcon}
              />
            </div>
          )}
          {showSubmissionButton && (
            <div
              className={classNames(
                styles.dropdownItem, {
                [styles.dropdownItemActive]: previewOpen === 'submission',
              }
              )}
              onClick={() => handleTogglePreview('submission')}
              data-tip="Show Submission"
            >
              <SubmissionIcon
                className={styles.dropdownIcon}
              />
            </div>
          )}
          {showReviewButton && (
            <div
              className={classNames(
                styles.dropdownItem, {
                [styles.dropdownItemActive]: previewOpen === 'review',
              }
              )}
              onClick={() => handleTogglePreview('review')}
              data-tip="Show Review"
            >
              <ReviewIcon
                className={styles.dropdownIcon}
              />
            </div>
          )}
          <ReactTooltip
            place="right"
            type="dark"
            effect="solid"
            data-delay-hide="1000"
            className={styles.toolTip}
          />
        </animated.div>
      ))}
      <div className={styles.center}>
        <div className={styles.titleContainer}>
          <span className={styles.title}>{title}</span>
          <span className={styles.subtitle}>{subtitle}</span>
        </div>
      </div>
      <div
        className={styles.right}
        onClick={() => handleBack()}
      >
        {closeText}
      </div>
      <ReactTooltip
        place="right"
        type="dark"
        effect="solid"
        data-delay-hide="1000"
        className={styles.toolTip}
      />
    </nav>
  );
};

AssignmentNavigation.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  setPreviewOpen: PropTypes.func.isRequired,
  showAssignmentButton: PropTypes.bool,
  showSubmissionButton: PropTypes.bool,
  showReviewButton: PropTypes.bool,
};

AssignmentNavigation.defaultProps = {
  title: '',
  subtitle: '',
  showAssignmentButton: true,
  showSubmissionButton: false,
  showReviewButton: false,
};

export default AssignmentNavigation;
