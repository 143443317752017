import { useQuery } from 'react-query';
import {
  getApiRequest,
  postApiRequest,
  putApiRequest,
  deleteApiRequest,
  patchApiRequest,
} from './base'

export const getTodos = async () => {
  return getApiRequest(`/api/student/todo-list/`);
}

export const getReviewTodo = (id) => {
  const result = useQuery(['review-todos', id], () => getReviewTodoDetail(id));
  return result;
};

export const getReviewTodoDetail = async (id) => {
  return postApiRequest(`/api/student/todo-details/`, { review_id: id });
}

export const getFeedbackTodo = (id) => {
  const result = useQuery(['feedback-todos', id], () => getFeedbackTodoDetail(id));
  return result;
};

export const getFeedbackTodoDetail = async (id) => {
  return postApiRequest(`/api/student/todo-details/`, { review_feedback_id: id });
}
