import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Switch, NavLink, useParams } from 'react-router-dom';
import classNames from 'classnames';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import { useUser } from '../../../queries/user';
import { useCourse, useSectionsForCourse, useAssignmentsForCourse, useSubmissionsForCourse } from '../../../queries/admin-queries';
import ProtectedRoute from '../../../routes/protected-route';
import Navigation from '../../../components/navigation/navigation';
import ScrollArea from '../../../components/scroll-area/scroll-area';
import Footer from '../../../components/footer/footer';
import Spinner from '../../../components/spinner/spinner';

import CourseDetailOverview from './course-detail-overview';
import CourseDetailSections from './course-detail-sections';
import CourseDetailAssignments from './course-detail-assignments';
import CourseDetailSubmissions from './course-detail-submissions';

import styles from './course-detail.module.scss';


const CourseDetail = (props) => {
  const { match } = props;
  const { path } = match;
  const { id } = useParams();
  const course = useCourse(id);
  const user = useUser();

  // get to reload them for the next pages
  const sections = useSectionsForCourse(id);
  const assignments = useAssignmentsForCourse(id);
  const submissions = useSubmissionsForCourse(id);


  if (course.isLoading || user.isLoading) {
    return <Spinner />;
  }

  return (
    <div className={styles.container}>
      <Navigation
        backURL="/dashboard"
        title={course.data.name}
        hasBackButton
      />
      <ScrollArea>
        <div className={styles.tabsContainer}>
          <div
            className={classNames(
              'container',
              styles.tabs,
            )}
          >
            <NavLink
              exact
              className={styles.tabLink}
              activeClassName={styles.tabLinkActive}
              to={`/courses/${id}`}
            >
              Course Overview
            </NavLink>
            <NavLink
              exact
              className={styles.tabLink}
              activeClassName={styles.tabLinkActive}
              to={`/courses/${id}/sections`}
            >
              Sections
            </NavLink>
            <NavLink
              exact
              className={styles.tabLink}
              activeClassName={styles.tabLinkActive}
              to={`/courses/${id}/assignments`}
            >
              All Assignments
            </NavLink>
            <NavLink
              exact
              className={styles.tabLink}
              activeClassName={styles.tabLinkActive}
              to={`/courses/${id}/submissions`}
            >
              All Submissions
            </NavLink>
          </div>
        </div>
        <div className={styles.switch}>
          <Switch>
            <ProtectedRoute exact path={`${path}`} component={CourseDetailOverview} />
            <ProtectedRoute path={`${path}/sections`} component={CourseDetailSections} />
            <ProtectedRoute path={`${path}/assignments`} component={CourseDetailAssignments} />
            <ProtectedRoute path={`${path}/submissions`} component={CourseDetailSubmissions} />
          </Switch>
        </div>
        <Footer />
      </ScrollArea>
    </div>
  );
};

export default CourseDetail;
