/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import styles from './course-list-item.module.scss';

const CourseListItem = (props) => {
  const { title, subtitle, id } = props;
  const history = useHistory();

  const handleClick = () => {
    history.push(`/courses/${id}`);
  };

  return (
    <div
      className={styles.container}
      onClick={handleClick}
    >
      <div className={styles.item}>
        <div className={styles.content}>
          <div className={styles.title}>{title}</div>
          <div className={styles.subtitle}>
            {subtitle}
          </div>
        </div>
      </div>
    </div>
  );
};

CourseListItem.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

CourseListItem.defaultProps = {
  title: '',
  subtitle: '',
};

export default CourseListItem;
