import { connect } from 'react-redux';

import {
  getCourse, createCourse, resetCourseCreated, updateCourse
} from '../../../actions/courses';
import { formApiAction } from '../../../actions/api';
import CourseCreate from './course-create';

const mapStateToProps = (state) => ({
  courses: state.courses.courses,
  courseDetail: state.courses.courseDetail,
  courseCreated: state.courses.courseCreated,
});

const mapDispatchToProps = (dispatch, ownProps) => {
  const formCreateCourse = formApiAction(createCourse);
  const formUpdateCourse = formApiAction(updateCourse);
  const { match: { params: { id } } } = ownProps;
  return {
    getCourse: () => dispatch(getCourse(id)),
    createCourse: (formData, setError) => dispatch(formCreateCourse(formData, setError)),
    updateCourse: (formData, setError) => dispatch(formUpdateCourse(formData, setError, id)),
    resetCourseCreated: () => dispatch(resetCourseCreated()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CourseCreate);
