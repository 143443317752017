import Cookies from 'js-cookie';
import { setAuthenticated, setLTI, clearAuthenticated } from '../common/localstorage';
import { useQuery } from 'react-query';

import {
  getApiRequest,
  postApiRequest,
  putApiRequest,
  deleteApiRequest,
  patchApiRequest,
} from './base'

export const userQuerySettings = {
  cacheTime: 60000,
  staleTime: 60000,
  retry: false,
};

export const getMe = async () => {
  const response = await fetch("/api/users/me/", {
    method: 'GET',
    credentials: 'same-origin',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'X-CSRFToken': Cookies.get('csrftoken'),
    }
  });

  if (!response.ok) {
    clearAuthenticated();
    throw new Error(response.statusText);
  }

  const data = await response.json();
  setAuthenticated();

  if (data.is_lti) {
    setLTI();
  }

  return data;
}

export const useUser = () => {
  return useQuery('me', getMe, userQuerySettings);
}

export const changePassword = async (formData) => {
  return postApiRequest(`/api/password/change/`, formData);
};

export const resetPassword = async (formData) => {
  return postApiRequest(`/api/password/reset/`, formData);
};

export const resetPasswordConfirm = async (formData) => {
  return postApiRequest(`/api/password/confirm/`, formData);
};

export const login = async (formData) => {
  return postApiRequest(`/api/login/`, formData);
};
