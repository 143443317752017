import React from 'react';
import PropTypes from 'prop-types';
import Dropzone from 'react-dropzone';
import classnames from 'classnames';

import AddSquare from '../../assets/icons/add-square.svg';

import styles from './file-upload.module.scss';

const FileUpload = (props) => {
  const { setBinaryString, label } = props;

  const handleOnDrop = (files) => {
    const reader = new FileReader();
    reader.onabort = () => console.log('file reading was aborted');
    reader.onerror = () => console.log('file reading has failed');
    reader.onload = () => {
      const binaryStr = reader.result;
      setBinaryString(binaryStr);
    };
    files.forEach((file) => reader.readAsText(file));
  };

  return (
    <Dropzone onDrop={(files) => handleOnDrop(files)}>
      {({ getRootProps, getInputProps, isDragActive }) => (
        <div
          {...getRootProps()}
          className={classnames(
            styles.container, {
            [styles.active]: isDragActive,
          },
          )}
        >
          <input {...getInputProps()} />
          <AddSquare />
          <span className={styles.label}>{label}</span>
        </div>
      )}
    </Dropzone>
  );
};

FileUpload.propTypes = {
  setBinaryString: PropTypes.func.isRequired,
  label: PropTypes.string
};

FileUpload.defaultProps = {
  label: 'Drag \'n\' drop or click to select a csv'
};

export default FileUpload;
