import React from 'react';
import { useHistory } from 'react-router-dom';
import { useQuery } from 'react-query';
import { getMe, userQuerySettings } from '../../queries/user';
import styles from './error.module.scss';

import Button from '../../components/button/button';

const ErrorView = () => {
  const history = useHistory();
  const user = useQuery('me', getMe, userQuerySettings);

  let path = '/login';
  let label = 'Go To Login';

  if (user.isLoading) {
    return (<div>Loading user...</div>);
  }

  if (user.isSuccess) {
    path = '/dashboard';
    label = 'Return to Dashboard';
  }

  return (
    <div className="page">
      <div className={styles.errorContainer}>
        <div className={styles.errorMessage}>
          <h1>Sorry something went wrong :(</h1>
          <Button onClick={() => history.push({ pathname: path })}>{label}</Button>
        </div>
      </div>
    </div>
  );
};

export default ErrorView;
