import React, { useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useQuery } from 'react-query';
import { getFormHelp, formHelpQuerySettings } from '../../../queries/help';
import Loading from '../../../components/loading/loading';
import Help from '../../../components/help';

const Criteria = ({ criteria, index, updateCriteria, removeCriteria }) => {

  return (
    <div className="w-full my-4">
      <div className="flex flex-inline justify-between items-center">
        <h4 className="font-semibold flex-grow">Criteria #{index + 1}</h4>
        <button
          className="flex flex-inline justify-between items-center hover:text-red-700"
          onClick={(e) => { e.preventDefault(); removeCriteria(criteria.id) }}
        >
          <svg fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 mr-2">
            <path strokeLinecap="round" strokeLinejoin="round" d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0" />
          </svg>
        </button>
      </div>
      <div className="w-full flex flex-inline mt-6">
        <input
          id="id_criteria"
          name="criteria"
          type="text"
          placeholder="Enter one way ChatGPT should evaluate the student's transcript for this answer"
          className="block w-4/5 bg-white py-1 px-2 text-base text-slate-900 outline outline-1 -outline-offset-1 outline-blue-300 placeholder:text-blue-300 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-blue-600 sm:pr-9 sm:text-sm/6"
          defaultValue={criteria.criteria}
          autoComplete="hidden"
          onChange={(value) => updateCriteria(criteria.id, "criteria", value.target.value)}
        />
        <input
          id="id_points"
          name="points"
          type="number"
          placeholder={"Enter points"}
          className="ml-4 block w-1/5 bg-white py-1 px-2 text-base text-slate-900 outline outline-1 -outline-offset-1 outline-blue-300 placeholder:text-blue-300 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-blue-600 sm:pr-9 sm:text-sm/6"
          defaultValue={criteria.points}
          autoComplete="hidden"
          onChange={(value) => updateCriteria(criteria.id, "points", value.target.value)}
        />
      </div>
    </div >
  )
}

const QuestionCriteria = ({ criteria, setCriteria }) => {
  const help = useQuery(['form-help', 'assignment-edit'], () => getFormHelp('assignment-edit'), formHelpQuerySettings);
  const addNewCriteria = (event) => {
    event.preventDefault();
    const newCriteria = [...criteria, { id: uuidv4(), criteria: '', points: 0 }];
    setCriteria(newCriteria);
  }

  const updateCriteria = (id, field, value) => {
    const newCriteria = [...criteria];
    const index = newCriteria.findIndex((criterion) => criterion.id === id);
    newCriteria[index][field] = value;
    setCriteria(newCriteria);
  }

  const removeCriteria = (id) => {
    const newCriteria = criteria.filter((criterion) => criterion.id !== id);
    setCriteria(newCriteria);
  }

  if (help.isLoading) return <Loading />

  return (
    <div className="w-full">
      <div className="flex flex-inline justify-between items-center">
        <h3 className="text-2xl fond-semibold flex-grow">
          Evaluation Criteria
          <Help />
        </h3>
        <button
          className="rounded py-2 px-4 text-lg text-white bg-blue-500 hover:bg-blue-700 cursor-pointer my-2"
          onClick={(event) => addNewCriteria(event)}
        >
          Add Criteria
        </button>
      </div>
      <hr className="border-1 border-solid border-black my-2" />

      <div className="my-4 bg-blue-300 border border-blue-500 p-6">
        <h4 className="text-xl">Example Evaluation Criteria</h4>
        <div className="grid grid-cols-2 gap-6 my-4">
          <div>
            <h5>Show Understanding of Concept(s)</h5>
            <ul className="list-disc my-2">
              <li className="ml-8">Accurately define the concept (20 points)</li>
              <li className="ml-8">Offer supporting evidence and/or specific examples (30 points)</li>
              <li className="ml-8">Offer practical application (10 points)</li>
              <li className="ml-8">Integrate concepts (10 points)</li>
            </ul>
          </div>
          <div>
            <h5>Show Understanding of Analysis</h5>
            <ul className="list-disc my-2">
              <li className="ml-8">Explain methodology clearly (20 points)</li>
              <li className="ml-8">Integrate envidence (20 points)</li>
              <li className="ml-8">Offer logical support (20 points)</li>
              <li className="ml-8">Support results (20 points)</li>
              <li className="ml-8">Support conclusion (20 points)</li>
            </ul>
          </div>
          <p className="my-2">NOTE: There is no need for the points to total 100</p>
        </div>
      </div>
      {criteria.map((c, index) => (
        <Criteria
          key={c.id}
          index={index}
          criteria={c}
          updateCriteria={updateCriteria}
          removeCriteria={removeCriteria}
        />
      ))}
    </div>
  )
}

export default QuestionCriteria
