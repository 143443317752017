import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import domtoimage from 'dom-to-image';

import Editor from '../../../components/editor/editor';
import Input from '../../../components/input/input';
import StepSection from '../../../components/step-section/step-section';

import styles from './assignment-bulk-create.module.scss';


const Step1 = (props) => {
  const {
    setAssignmentDraft, goToNextStep, assignmentDraft
  } = props;
  const {
    register, handleSubmit, formState: { errors } } = useForm({
      defaultValues: {
        title: assignmentDraft.title
      }
    });

  const [editorDraftState, setEditorDraftState] = useState({
    body: assignmentDraft.body,
    plain_text: assignmentDraft.plain_text,
    thumbnail: assignmentDraft.thumbnail,
  });

  const onSubmit = (data) => {
    const formData = data;
    formData.body = editorDraftState.body;
    formData.body_plaintext = editorDraftState.plain_text;
    const node = document.getElementsByClassName('DraftEditor-editorContainer')[0];

    domtoimage.toJpeg(node, {
      quality: 0.35,
      width: 896,
      height: 1005,
      style: {
        width: '896px',
        height: '1005px',
        overflow: 'hidden',
        backgroundColor: 'white',
        padding: 0,
        margin: 0,
        paddingLeft: '96px',
        paddingRight: '96px',
        marginTop: '146px,'
      }
    }).then((dataURL) => {
      formData.thumbnail = dataURL;
      setAssignmentDraft(formData);
      goToNextStep();
    }).catch((error) => {
      setAssignmentDraft(formData);
      goToNextStep();
    });
  };

  const handleScroll = (e) => {
    const node = document.getElementsByClassName('rdw-editor-toolbar')[0];
    if (e.target.scrollTop > 162) {
      node.classList.add('stickyToolbar');
    } else {
      node.classList.remove('stickyToolbar');
    }
  };

  return (
    <form
      className={styles.step}
      id="step-form"
      onSubmit={handleSubmit(onSubmit)}
      onScroll={handleScroll}
    >
      <StepSection noPadding shadow={false}>
        <Input
          label="Assignment Title"
          name="title"
          ref={register({ required: true })}
          error={errors.title}
        />
      </StepSection>
      <Editor
        body={assignmentDraft.body}
        handleBodyUpdate={setEditorDraftState}
      />
    </form>
  );
};

Step1.propTypes = {
  setAssignmentDraft: PropTypes.func.isRequired,
};

Step1.defaultProps = {
};

export default Step1;
