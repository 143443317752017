import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import { useParams } from 'react-router';

import Modal from '../../../components/modal/modal';
import Input from '../../../components/input/input';
import Button from '../../../components/button/button';

import styles from './participant-create.module.scss';


const ParticipantCreate = (props) => {
  const { id } = useParams();
  const history = useHistory();
  const {
    createSingleSectionParticipant, resetParticipantsCreated, participantsCreated
  } = props;
  const {
    register, handleSubmit, setError, isSubmitting, formState: { errors }
  } = useForm();

  useEffect(() => {
    if (participantsCreated) {
      toast('Participant Created', { containerId: 'RIGHT' });
      resetParticipantsCreated();
      history.goBack();
    }
  }, [participantsCreated]);

  useEffect(() => {
    resetParticipantsCreated();
    return () => {
      resetParticipantsCreated();
    };
  }, []);

  const onSubmit = (data) => {
    const formData = data;
    formData.section = id;
    createSingleSectionParticipant(formData, setError);
  };


  return (
    <Modal title="Create Participant">
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className={styles.row}>
          <Input
            label="Email"
            name="email"
            error={errors.email}
            {...register('email', { required: true })}
          />
        </div>
        <div className={styles.row}>
          <Input
            label="First Name"
            name="first_name"
            error={errors.first_name}
            {...register('first_name', { required: true })}
          />
        </div>
        <div className={styles.row}>
          <Input
            label="Last Name"
            name="last_name"
            error={errors.last_name}
            {...register('last_name', { required: true })}
          />
        </div>
        <Button
          type="submit"
          disabled={isSubmitting}
        >
          Create Participant
        </Button>
      </form>
    </Modal>
  );
};

ParticipantCreate.propTypes = {
  createSingleSectionParticipant: PropTypes.func.isRequired,
  participantsCreated: PropTypes.bool,
  resetParticipantsCreated: PropTypes.func.isRequired,
};

ParticipantCreate.defaultProps = {
  participantsCreated: false,
};

export default ParticipantCreate;
