import { connect } from 'react-redux';

import { getUserSubmissions } from '../../../actions/submissions';
import { getUserReviews } from '../../../actions/reviews';
import { getUserReviewFeedbacks } from '../../../actions/review-feedbacks';
import { submissionsSelector } from '../../../selectors/submissions';
import { reviewsSelector } from '../../../selectors/reviews';
import { reviewFeedbacksSelector } from '../../../selectors/review-feedbacks';

import ParticipantDetail from './participant-detail';


const mapStateToProps = (state) => ({
  submissions: submissionsSelector(state.submissions.submissions),
  reviews: reviewsSelector(state.reviews.reviews),
  reviewFeedbacks: reviewFeedbacksSelector(state.reviewFeedbacks.reviewFeedbacks),
});

const mapDispatchToProps = (dispatch) => ({
  getUserSubmissions: (id) => dispatch(getUserSubmissions(id)),
  getUserReviews: (id) => dispatch(getUserReviews(id)),
  getUserReviewFeedbacks: (id) => dispatch(getUserReviewFeedbacks(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ParticipantDetail);
