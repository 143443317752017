import React from 'react';
import { Link } from 'react-router-dom';

import Navigation from '../../../components/navigation/navigation';
import List from '../../../components/list/list';
import ScrollArea from '../../../components/scroll-area/scroll-area';
import CourseListItem from '../../../components/course-list-item/course-list-item';
import Footer from '../../../components/footer/footer';
import AddIcon from '../../../assets/icons/add-square.svg';

import { useUser } from '../../../queries/user';
import { useCourses } from '../../../queries/admin-queries';
import styles from './dashboard.module.scss';


const Dashboard = () => {
  const user = useUser();
  const courses = useCourses();

  if (user.isLoading || courses.isLoading) {
    return (<div>Loading...</div>);
  }

  return (
    <div className={styles.container}>
      <Navigation />
      <ScrollArea>
        <div className={styles.intro}>
          <div className={styles.welcome}>
            <h3>
              Welcome Back,
              {' '}
              {user.data.first_name}
              !
            </h3>
            <span>
              This is the administrator dashboard. Here you can create new Courses
              or administer your existing Courses.
            </span>
          </div>
        </div>
        <List>
          {!user.data.is_lti &&
            <div className={styles.button}>
              <Link
                className={styles.buttonInner}
                to={{ pathname: '/courses/create', state: { modal: true } }} >
                <AddIcon />
                <span>Create new Course</span>
              </Link>
            </div>
          }
          {courses.data.results.map((item, index) => (
            <CourseListItem
              id={item.id}
              title={item.name}
              key={item.id}
              createdDate={item.created}
              showCreatedDate={user.data.is_lti}
            />
          ))}
        </List>
        <Footer />
      </ScrollArea>
    </div>
  );
};

export default Dashboard;
