import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import { useParams, useHistory } from 'react-router-dom';
import { useUser } from '../../../queries/user';
import {
  useOralAssignment, useOralSubmissionDetail
} from '../../../queries/admin-queries';
import Loading from '../../../components/loading/loading';

const buttonClasses = classNames('rounded py-2 px-4 text-lg text-white bg-blue-500 hover:bg-blue-700 w-full cursor-pointer my-1');
const greenButtonClasses = classNames('rounded py-2 px-4 text-lg text-white bg-green-500 hover:bg-green-700 w-full cursor-pointer my-1');

const CurrentState = ({ state }) => {
  // Handle colors
  const classes = classNames('rounded py-2 px-4 text-lg text-white bg-gray-800 uppercase', {
    'bg-gray-400': state === 'pending',
    'bg-green-500': state === 'active',
    'bg-red-500': state === 'completed',
  });

  return (
    <span className={classes}>{state}</span>
  );
};



const OralSubmissionVideo = (props) => {
  const { id, sectionMemberID, submissionID } = useParams();
  const history = useHistory();
  const user = useUser();
  const assignment = useOralAssignment(id);
  const submissions = useOralSubmissionDetail(id, sectionMemberID);

  if (user.isLoading || assignment.isLoading || submissions.isLoading) {
    return <Loading />;
  }

  const sub = submissions.data.find((s) => s.id == submissionID);

  return (
    <React.Fragment>
      <div className="flex flex-row items-center">
        <h1 className="my-4 text-2xl font-bold flex-grow">Submission Video - {user.data.first_name} {user.data.last_name}</h1>
        <span className="flex-initial mx-2">
          <CurrentState state={assignment.data.state} />
        </span>
      </div>
      <hr className="border-1 border-solid border-black my-4" />
      <div className="mt-6">
        <div className="w-full flex items-center justify-center">
          <video
            src={sub.video_file}
            width='800'
            height='600'
            controls
          />
        </div>
      </div>
      <div className="mt-12">
        <button
          className={buttonClasses}
          onClick={() => history.push(`/oral/assignments/${id}/submission/detail/${sectionMemberID}`)}>
          Return to Submission Details
        </button>
      </div>
    </React.Fragment>
  );
};

export default OralSubmissionVideo;
