import { connect } from 'react-redux';

import {
  getReview
} from '../../../actions/reviews';
import ReviewDetail from './review-detail';

const mapStateToProps = (state) => ({
  reviewDetail: state.reviews.reviewDetail,
});

const mapDispatchToProps = (dispatch, ownProps) => {
  const { match: { params: { id } } } = ownProps;
  return {
    getReview: () => dispatch(getReview(id)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ReviewDetail);
