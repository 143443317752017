/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import RubricInjectWidget from '../rubric-inject-widget/rubric-inject-widget';
import RubricWidget from '../rubric-widget/rubric-widget';
import RubricForm from '../rubric-form/rubric-form';
import PreviewIcon from '../../assets/icons/view-1.svg';
import PreviewOffIcon from '../../assets/icons/view-off.svg';
import Loading from '../../components/loading/loading';
import HelpLabel from '../help-label';
import { useQuery } from 'react-query';
import { getMe, userQuerySettings } from '../../queries/user';
import styles from './rubric-create-form.module.scss';

function RubricCreateForm(props) {
  const { rubricState, setRubricState, name, help } = props;
  const user = useQuery('me', getMe, userQuerySettings);
  const [previewState, setPreviewState] = useState(false);

  if (user.isLoading) {
    return (<Loading />);
  }
  // Dummy rubric data
  // const [rubricState, setRubricState] = useState([
  //   {
  //     id: '1asdfasdf',
  //     type: 'bool',
  //     label: 'Did you like the class?',
  //     answer: false,
  //   },
  //   {
  //     id: '2asfsfd',
  //     type: '5-stars',
  //     label: 'How much did you like the class?',
  //     answer: 0, // Becomes user entered score
  //   },
  //   {
  //     id: '3sdfaf',
  //     label: 'description',
  //     body: 'Below here you should write a whole bunch of stuff',
  //   },
  //   {
  //     id: '4safsaf',
  //     type: 'text',
  //     label: 'How did the author make your feel',
  //     answer: '',
  //   }
  // ]);

  const handleTogglePreview = () => {
    setPreviewState(!previewState);
  };

  const preview = (
    <div className={styles.previewContainer}>
      <div className={styles.previewContent}>
        <RubricForm
          data={rubricState}
          readOnly
        />
      </div>
    </div>
  );

  let questionNumber = 0;
  const inputs = rubricState.map((item, index) => {
    if (item.type !== "description") {
      questionNumber++;
    }
    return (
      <div className={styles.item} key={item.id}>
        <RubricWidget
          index={index}
          item={item}
          rubricState={rubricState}
          setRubricState={setRubricState}
          questionNumber={questionNumber}
        />
        <RubricInjectWidget
          index={index + 1}
          rubricState={rubricState}
          setRubricState={setRubricState}
        />
      </div>
    );
  });

  let stageHelp = <React.Fragment>
    <p className="my-4">The white text box below shows text that students will see that you may edit.</p>
    <p className="my-4">You will add your Stage 2: Review evaluation questions for students by clicking the <strong>+</strong> below.</p>
    <p className="my-4">That will bring up 3 icons: 1) Instructions, 2) 5-Stars/Quantitative, and 3) Text/Qualitative. </p>
    <ul className="my-4 list-disc ml-6">
      <li>You may use the “Instructions” button to offer context, or to create a heading that groups questions together</li>
      <li>Choose "Student View" to see students' view</li>
      <li>Save your edits before hitting "Save and Continue"</li>
    </ul>
  </React.Fragment>;

  let topLabel = "Create Your Stage 2 Questions"
  if (user.data.is_demo) {
    topLabel = "Best Practices for Peer - Reviewed Questions"
    stageHelp = <React.Fragment>
      <p className="my-4">Add your Stage 2 evaluation questions for students by clicking the <strong>+</strong> below.</p>
      <p className="my-4">This will bring up 3 icons: 1) Instructions, 2) 5-Stars/Quantitative, and 3) Text/Qualitative</p>
      <p className="my-4">Choose "Student View" to see the students' view</p>
      <p className="my-4">Save your edits before hitting "Save and Continue".</p>
    </React.Fragment>
  }

  // Handle stage 5 rubric
  if (name === "step_5_rubric") {
    topLabel = "Accept or Create Your Stage 3 Questions"
    stageHelp = <p className="my-4">Below are default questions that you can easily edit by using the pencil icon. Be sure to SAVE your edits</p>
  }

  return (
    <div className={styles.container}>
      <div className="m-2 bg-blue-100 text-lg border border-blue-400 p-6">
        <HelpLabel label={topLabel} name={name} help={help} />
        {stageHelp}
      </div>
      <div className={styles.item}>
        <RubricInjectWidget
          index={0}
          rubricState={rubricState}
          setRubricState={setRubricState}
        />
      </div>

      {inputs}
      {previewState && (
        preview
      )}

      <div
        className={styles.previewButton}
        onClick={handleTogglePreview}
      >
        {!previewState && (
          <>
            <PreviewIcon />
            <span>Student View</span>
          </>
        )}
        {previewState && (
          <>
            <PreviewOffIcon />
            <span>Close Student View</span>
          </>
        )}
      </div>
    </div>
  );
}

RubricCreateForm.propTypes = {
  rubricState: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  setRubricState: PropTypes.func.isRequired,
};

RubricCreateForm.defaultProps = {
};

export default RubricCreateForm;
