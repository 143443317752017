import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import { useParams } from 'react-router';
import { useUser } from '../../../queries/user';
import Modal from '../../../components/modal/modal';
import Input from '../../../components/input/input';
import Button from '../../../components/button/button';


import styles from './course-create.module.scss';


const CourseCreate = (props) => {
  const { id } = useParams();
  const history = useHistory();
  const {
    createCourse, courseCreated, resetCourseCreated, getCourse, courseDetail,
    updateCourse
  } = props;
  let defaultValues = {};
  if (id) {
    defaultValues = {
      name: courseDetail.name,
      description: courseDetail.description
    };
  }
  const user = useUser();
  const {
    register, handleSubmit, setError, isSubmitting, formState: { errors }
  } = useForm({
    defaultValues
  });

  let modalTitle = 'Create a new Course';
  let buttonLabel = 'Create Course';
  let toastLabel = 'Course Created!';

  if (id) {
    modalTitle = 'Update Course';
    buttonLabel = 'Update Course';
    toastLabel = 'Course Updated!';
  }

  useEffect(() => {
    if (courseCreated) {
      toast(toastLabel, { containerId: 'RIGHT' });
      resetCourseCreated();
      history.goBack();
    }
  }, [courseCreated]);

  useEffect(() => {
    if (id) {
      getCourse();
    }
    resetCourseCreated();
    return () => {
      resetCourseCreated();
    };
  }, []);

  const onSubmit = (data) => {
    const formData = data;
    formData.org = user.user_org_id;
    if (id) {
      updateCourse(formData, setError);
    } else {
      createCourse(formData, setError);
    }
  };

  if (user.isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <Modal title={modalTitle}>
      <form onSubmit={handleSubmit(onSubmit)} >
        <div className={styles.row}>
          <Input
            label="Course Name"
            name="name"
            error={errors.name}
            {...register('name', { required: true })}
          />
        </div>
        <div className={styles.row}>
          <Input
            label="Description"
            name="description"
            type="textarea"
            error={errors.description}
            {...register('description', { required: true })}
          />
        </div>
        <Button
          type="submit"
          disabled={isSubmitting}
        >
          {buttonLabel}
        </Button>
      </form>
    </Modal>
  );
};

CourseCreate.propTypes = {
  createCourse: PropTypes.func.isRequired,
  courseCreated: PropTypes.bool,
  resetCourseCreated: PropTypes.func.isRequired,
  getCourse: PropTypes.func.isRequired,
  course: PropTypes.shape({}),
  updateCourse: PropTypes.func.isRequired,
};

CourseCreate.defaultProps = {
  courseCreated: false,
  course: {},
};

export default CourseCreate;
