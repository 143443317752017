import React from 'react';
import classNames from 'classnames';
import { useHistory, } from 'react-router';
import { createOralAssignment } from '../../../queries/admin-queries';
import { useMutation, useQueryClient } from 'react-query';
const buttonClasses = classNames('rounded py-2 px-4 text-lg text-white bg-blue-500 hover:bg-blue-700 w-full cursor-pointer my-1');


const CreateNewOralAssignmentButton = (props) => {
  const { sectionID, label } = props;
  const history = useHistory();
  const queryClient = useQueryClient();

  const mutation = useMutation('create-oral-assignment', (values) => createOralAssignment(values));
  const createNewAssignment = () => {
    const initialData = {
      section: sectionID,
    }
    console.log("InitialData", initialData);
    mutation.mutate(initialData, {
      onSuccess: (data, values) => {
        queryClient.invalidateQueries(["admin-assignments-list-course"]);
        queryClient.invalidateQueries(["admin-assignments-list-section"]);
        console.log("Created oral assignment!")
        console.dir(data);
        console.dir(values);
        let url = `/oral/assignments/${data.id}/edit`;
        console.log(`URL: ${url}`);
        history.push(url);
      },
      onError: (error, variables, context) => {
        console.log(error.response, variables, context);
        history.push("/");
      },
    });
  };

  return (
    <a
      className={buttonClasses}
      onClick={createNewAssignment}
    >
      {label}
    </a>
  );
};

export default CreateNewOralAssignmentButton;
