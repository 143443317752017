import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import { useParams, useHistory } from 'react-router-dom';
import { useUser } from '../../../queries/user';
import {
  useOralAssignment, useOralSubmissionDetail
} from '../../../queries/admin-queries';
import Loading from '../../../components/loading/loading';
import { CopyToClipboard } from 'react-copy-to-clipboard';

const buttonClasses = classNames('rounded py-2 px-4 text-lg text-white bg-blue-500 hover:bg-blue-700 w-full cursor-pointer my-1');
const greenButtonClasses = classNames('rounded py-2 px-4 text-lg text-white bg-green-500 hover:bg-green-700 w-full cursor-pointer my-1');

const CurrentState = ({ state }) => {
  // Handle colors
  const classes = classNames('rounded py-2 px-4 text-lg text-white bg-gray-800 uppercase', {
    'bg-gray-400': state === 'pending',
    'bg-green-500': state === 'active',
    'bg-red-500': state === 'completed',
  });

  return (
    <span className={classes}>{state}</span>
  );
};



const OralSubmissionTranscript = (props) => {
  const { id, sectionMemberID, submissionID } = useParams();
  const history = useHistory();
  const user = useUser();
  const assignment = useOralAssignment(id);
  const submissions = useOralSubmissionDetail(id, sectionMemberID);

  if (user.isLoading || assignment.isLoading || submissions.isLoading) {
    return <Loading />;
  }

  const sub = submissions.data.find((s) => s.id == submissionID);

  return (
    <React.Fragment>
      <div className="flex flex-row items-center">
        <h1 className="my-4 text-2xl font-bold flex-grow">Submission Video - {user.data.first_name} {user.data.last_name}</h1>
        <span className="flex-initial mx-2">
          <CurrentState state={assignment.data.state} />
        </span>
      </div>
      <hr className="border-1 border-solid border-black my-4" />
      <div className="mt-6">
        <div className="flex flex-inline justify-between">
          <h2 className="text-xl font-semibold my-2">Transcript</h2>
          <CopyToClipboard text={sub.transcript} className="text-gray-500 hover:text-gray-700 flex flex-inline items-center">
            <button className="flex flex-inline">
              <svg fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 mr-1">
                <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 7.5V6.108c0-1.135.845-2.098 1.976-2.192.373-.03.748-.057 1.123-.08M15.75 18H18a2.25 2.25 0 0 0 2.25-2.25V6.108c0-1.135-.845-2.098-1.976-2.192a48.424 48.424 0 0 0-1.123-.08M15.75 18.75v-1.875a3.375 3.375 0 0 0-3.375-3.375h-1.5a1.125 1.125 0 0 1-1.125-1.125v-1.5A3.375 3.375 0 0 0 6.375 7.5H5.25m11.9-3.664A2.251 2.251 0 0 0 15 2.25h-1.5a2.251 2.251 0 0 0-2.15 1.586m5.8 0c.065.21.1.433.1.664v.75h-6V4.5c0-.231.035-.454.1-.664M6.75 7.5H4.875c-.621 0-1.125.504-1.125 1.125v12c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V16.5a9 9 0 0 0-9-9Z" />
              </svg>

              <span>Copy Transcript</span>
            </button>
          </CopyToClipboard>
        </div>
        {!sub.transcript && <p>No transcript available for this submission.</p>}
        <p className="my-4 leading-7">{sub.transcript}</p>
      </div>
      <div className="mt-12">
        <button
          className={buttonClasses}
          onClick={() => history.push(`/oral/assignments/${id}/submission/detail/${sectionMemberID}`)}>
          Return to Submission Details
        </button>
      </div>
    </React.Fragment>
  );
};

export default OralSubmissionTranscript;
