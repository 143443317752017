import React from 'react';
import Cookies from 'js-cookie';
import { useParams } from 'react-router-dom';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import { useForm } from 'react-hook-form';
import Input from '../../components/input/input';
import Button from '../../components/button/button';
import Logo from './logo';
import styles from './login.module.scss';

const ForgotPasswordConfirm = (props) => {
  const {
    register, handleSubmit, setError, formState
  } = useForm();

  const { uid, token } = useParams();
  const queryClient = useQueryClient();
  const apiErrors = useQuery('password-reset-form-errors', () => ({}));

  const mutatePassword = useMutation((formData) => {
    fetch('/api/reset/confirm/', {
      method: 'POST',
      credentials: 'same-origin',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-CSRFToken': Cookies.get('csrftoken'),
      },
      body: JSON.stringify(formData)
    }).then((response) => {
      if (response.status != 200) {
        queryClient.setQueryData('password-reset-form-errors', { error: true });
      }
    });
  });

  const onSubmit = (data) => {
    data.uid = uid;
    data.token = token;

    if (data.new_password1 !== data.new_password2) {
      setError('new_password1', 'doesNotMatch', 'Passwords do not match!');
      setError('new_password2', 'doesNotMatch', 'Passwords do not match!');
      return;
    }
    mutatePassword.mutate(data);
  };

  const submitted = formState.isSubmitted;
  let apiError = false;

  // Handle api errors
  if (apiErrors.isSuccess && apiErrors.data.error) {
    apiError = true;
  }

  return (
    <div className={styles.container}>
      <div className="w-full max-w-xs">
        <div className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
          <Logo />
          {!submitted
            && (
              <form onSubmit={handleSubmit(onSubmit)}>
                <p className="my-4">
                  Enter a new password to reset your account:
                </p>
                <div className="mb-4">
                  <Input
                    label="Password"
                    name="new_password1"
                    error={formState.errors.new_password1}
                    type="password"
                    {...register('new_password1', { required: true })}
                  />
                </div>
                <div className="mb-4">
                  <Input
                    label="Confirm Password"
                    name="new_password2"
                    error={formState.errors.new_password2}
                    type="password"
                    {...register('new_password2', { required: true })}
                  />
                </div>
                <div className="w-full items-center">
                  <Button
                    type="submit"
                  >
                    Reset Password
                  </Button>

                </div>
              </form>
            )}
          {submitted && !apiError
            && (
              <div>
                <p className="my-4">
                  Your password has been changed.
                </p>
                <p className="my-4">
                  Please
                  {' '}
                  <a href="/login" className="text-blue-800 hover:text-blue-600 underline">login again</a>.
                </p>
              </div>
            )}
          {apiError
            && (
              <div>
                <p className="my-4">
                  <span className="text-red-800 font-bold">ERROR:</span>
                  {' '}
                  Your password could not be changed. This can happen if
                  your password is too short or if the password reset email has
                  expired.
                </p>
                <p className="my-4">
                  Please
                  {' '}
                  <a href="/password-reset/" className="text-blue-800 hover:text-blue-600 underline">try to reset your password</a>
                  {' '}
                  again.
                </p>
              </div>
            )}
        </div>
      </div>
    </div>
  );
};

export default ForgotPasswordConfirm;
