/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import produce from 'immer';
import { v4 as uuidv4 } from 'uuid';

import TextIcon from '../../assets/icons/paragraph-left-align.svg';
import StarIcon from '../../assets/icons/ranking-star-top.svg';
import ICircleIcon from '../../assets/icons/i-circle.svg';

import styles from './rubric-inject-widget.module.scss';

const RubricInjectWidget = (props) => {
  const {
    rubricState, setRubricState, index,
  } = props;
  const [editModeState, setEditModeState] = useState(false);

  const handleClickAdd = () => {
    setEditModeState(!editModeState);
  };

  const handleAddItem = (type) => {
    setEditModeState(false);
    const template = {
      id: uuidv4(),
      label: '',
      type: type,
      answer: null,
    };
    const newState = produce(rubricState, (draftState) => {
      draftState.splice(index, 0, template);
    });
    setRubricState(newState);
  };

  const renderContent = () => {
    if (editModeState) {
      return (
        <div className={styles.content}>
          <div
            className={styles.contentIconWhite}
            onClick={() => handleAddItem('description')}
          >
            <ICircleIcon />
            <div>Instructions</div>
          </div>
          <div
            className={styles.contentIcon}
            onClick={() => handleAddItem('5-stars')}
          >
            <StarIcon />
            <div>5-Stars</div>
          </div>
          <div
            className={styles.contentIcon}
            onClick={() => handleAddItem('text')}
          >
            <TextIcon />
            <div>Text</div>
          </div>
        </div>
      );
    }
    return null;
  };

  return (
    <div className={classNames(
      styles.container, {
      [styles.editMode]: editModeState,
    }
    )}
    >
      {renderContent()}
      <div className={styles.plusBar}>
        <div
          className={styles.plusButton}
          onClick={handleClickAdd}
        >
          +
        </div>
      </div>
    </div>
  );
};

RubricInjectWidget.propTypes = {
  index: PropTypes.number.isRequired,
  rubricState: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  setRubricState: PropTypes.func.isRequired,
};

RubricInjectWidget.defaultProps = {
};

export default RubricInjectWidget;
