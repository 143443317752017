import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { Link } from 'react-router-dom';
import Input from '../../components/input/input';
import Button from '../../components/button/button';
import { useHistory } from 'react-router-dom';
import { isAuthenticated, setAuthenticated } from '../../common/localstorage';
import { useInterval } from '../../common/utils';
import { login } from '../../queries/user';

import styles from './login.module.scss';


const Login = (props) => {
  const history = useHistory();
  const [retryCount, setRetryCount] = useState(0);

  const mutation = useMutation('login', (data) => login(data));

  const {
    register, handleSubmit, setError, formState: { errors, isSubmitting, isSubmitted }
  } = useForm();

  const onSubmit = (data) => {
    mutation.mutate(data, {
      onSuccess: (data, values) => {
        setAuthenticated();
        history.push('/dashboard');
      },
      onError: (error, variables, context) => {
        console.log(error.response, variables, context);
      }
    });
  };

  useInterval(() => {
    if (retryCount < 30) {
      if (isAuthenticated(false)) {
        history.push('/dashboard');
      }
    }
    setRetryCount(retryCount + 1);
  }, 250);

  useEffect(() => {
    setRetryCount(0);
  }, [isSubmitting, isSubmitted])

  return (
    <div className={styles.container}>
      <div className="w-full max-w-xs">
        <form onSubmit={handleSubmit(onSubmit)} className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
          <h1 className="text-2xl font-bold my-4">Peer Exchange Login</h1>
          <div className="mb-4">
            <Input
              label="Email"
              name="email"
              error={errors.email}
              {...register('email', { required: true })}
              autofocus
            />
          </div>
          <div className="mb-6">
            <Input
              label="Password"
              name="password"
              error={errors.password}
              type="password"
              {...register('password', { required: true })}
            />
          </div>
          <Button
            type="submit"
          >
            Login
          </Button>
        </form>
        <div className="w-full p-4 text-center">
          <Link to="/password-reset/" className="text-blue-800 hover:text-blue-600 underline">Forgotten your password?</Link>
        </div>
      </div>
    </div>
  );
};

export default Login;
