import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import rootReducer from '../reducers';

export default function configureStore(initialState, middleware) {
  return createStore(
    rootReducer,
    {
      ...initialState,
    },
    composeWithDevTools(applyMiddleware(...middleware)),
  );
}
