import React from 'react';
import { useParams } from 'react-router-dom';

import { useAssignmentListForCourse } from '../../../queries/admin-queries';
import AssignmentListItem from '../../../components/assignment-list-item/assignment-list-item';
import List from '../../../components/list/list';

import styles from './course-detail.module.scss';


const CourseDetailOverview = (props) => {
  const { id } = useParams();
  const assignments = useAssignmentListForCourse(id);

  if (assignments.isLoading) {
    return <p>Loading...</p>;
  }

  return (
    <section className={styles.section}>
      <List>
        {assignments.data.length === 0 && (
          <h3>This Course currently has no assignments</h3>
        )}
        {assignments.data.map((assignment) => (
          <AssignmentListItem
            history={history}
            id={assignment.id}
            title={assignment.title}
            dueDate={assignment.due_date}
            state={assignment.state}
            paused={assignment.paused}
            key={assignment.id}
            active={assignment.active}
            type={assignment.type}
          />
        ))}
      </List>
    </section>
  );
};


export default CourseDetailOverview;
