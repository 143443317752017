import React, { useState, useEffect } from 'react';
import moment from "moment-timezone";
import { useParams, useHistory } from 'react-router-dom';
import { useForm, useFormContext, FormProvider, Controller } from 'react-hook-form';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import classNames from 'classnames';
import StepSection from '../../../components/step-section/step-section';
import LeaveWarning from '../../../components/leave-warning/leave-warning';
import Loading from '../../../components/loading/loading';
import { useUser } from '../../../queries/user';
import {
  useEmbeddedOralQuestions, useOralQuestionTypes, createNewQuestion,
  useOralQuestionDetail, updateQuestion, deleteQuestion
} from '../../../queries/admin-queries';
import Select from 'react-select'
import QuestionCriteria from '../oral-detail/question-criteria';

const buttonClasses = classNames('rounded py-2 px-4 text-lg text-white bg-blue-500 hover:bg-blue-700 w-full cursor-pointer my-1');
const redButtonClasses = classNames('rounded py-2 px-4 text-lg text-white bg-red-500 hover:bg-red-700 w-full cursor-pointer my-1');

const Input = ({ label, name, type = 'text', placeholder = undefined, value = undefined }) => {
  const { register, setValue, formState: { errors } } = useFormContext();
  const errorMessage = errors[name]?.message?.toString()

  useEffect(() => {
    if (value) {
      setValue(name, value)
    }
  }, [value]);

  return (
    <div className="w-full">
      <label htmlFor={`id_${name}`} className="block text-sm/6 font-semibold text-gray-900">
        {label}
      </label>
      <div className="mt-6 w-full">
        <input
          id={`id_${name}`}
          name={name}
          type={type}
          placeholder={placeholder}
          className="block w-full bg-white py-1 px-2 text-base text-slate-900 outline outline-1 -outline-offset-1 outline-blue-300 placeholder:text-blue-300 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-blue-600 sm:pr-9 sm:text-sm/6"
          {...register(name)}
        />
      </div>
      {errorMessage && (
        <p id="email-error" className="mt-2 text-sm text-red-600">
          {errorMessage}
        </p>
      )}
    </div>
  )
}

const TextInput = ({ label, name, placeholder = undefined, value = undefined, autoFocus = false, rows = 3 }) => {
  const { register, setValue, formState: { errors } } = useFormContext();
  const errorMessage = errors[name]?.message?.toString()

  useEffect(() => {
    if (value) {
      setValue(name, value)
    }
  }, [value]);

  return (
    <div className="w-full">
      <label htmlFor={`id_${name}`} className="block text-sm/6 font-semibold text-gray-900">
        {label}
      </label>
      <div className="mt-6 w-full">
        <textarea
          id={`id_${name}`}
          name={name}
          placeholder={placeholder}
          className="block w-full bg-white py-1 px-2 text-base text-slate-900 outline outline-1 -outline-offset-1 outline-blue-300 placeholder:text-blue-300 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-blue-600 sm:pr-9 sm:text-sm/6"
          autoFocus={autoFocus}
          rows={rows}
          {...register(name)}
        />
      </div>
      {errorMessage && (
        <p id="email-error" className="mt-2 text-sm text-red-600">
          {errorMessage}
        </p>
      )}
    </div>
  )
}
const DeleteQuestion = ({ assignmentID, questionID, setAction }) => {
  const queryClient = useQueryClient();
  const [confirm, setConfirm] = useState(false);

  const mutation = useMutation('delete-oral-question', (questionID) => deleteQuestion(questionID));

  const handleDelete = () => {
    mutation.mutate(questionID, {
      onSuccess: () => {
        queryClient.invalidateQueries(["admin-embedded-oral-questions"]);
        setAction(["list"]);
      }
    });
  }

  if (confirm) {
    return (
      <div className="mt-12 text-center">
        <h3 className="text-xl font-semibold">This operation cannot be undone.  Are you sure?</h3>
        <button
          className={redButtonClasses}
          onClick={handleDelete}
        >
          Yes, delete this question.
        </button>
      </div>
    )
  } else {
    return (
      <div className="mt-12">
        <button
          className={redButtonClasses}
          onClick={() => setConfirm(true)}
        >
          Delete Question
        </button>
      </div>
    )
  }
}

const EditQuestion = ({ setAction, oralAssignmentID, questionID }) => {
  const user = useUser();

  const [criteria, setCriteria] = useState([]);

  const queryClient = useQueryClient();
  const question = useOralQuestionDetail(questionID);

  // FIXME
  const mutation = useMutation('update-oral-question', (data) => updateQuestion(questionID, data));
  const questionTypes = useOralQuestionTypes();

  const form = useForm();

  // Set initial data from API
  useEffect(() => {
    if (question.isSuccess) {
      if (!form.formState.isDirty) {
        setCriteria(question.data.criteria);
        form.setValue("question_type_id", question.data.question_type.id);
        form.setValue("question_text", question.data.question_text);
        form.setValue("academic_context", question.data.academic_context);
        form.setValue("topic_concept", question.data.topic_concept);
        form.setValue("ai_context", question.data.ai_context);
      }
    }
  }, [question.data]);


  if (user.isLoading || questionTypes.isLoading || question.isLoading) {
    return <Loading />;
  }

  // Limit question types to those that don't need reviews
  const questionOptions = questionTypes.data.map(
    (type) => ({ value: type.id, label: type.name })
  );


  const onSubmit = (data) => {
    console.log("Updating question...")
    data.criteria = criteria;
    console.dir(data)

    mutation.mutate(data, {
      onSuccess: (data, values) => {
        queryClient.invalidateQueries(["admin-embedded-oral-questions"]);
        form.reset();
        setAction(["list"]);
      },
      onError: (error, variables, context) => {
        console.log(error.response, variables, context);
      }
    });
  }

  const buttonGroupClasses = classNames("flex items-center float-right my-6", {
  })

  return (
    <div className="p-6">
      <div className="flex flex-row items-center">
        <h1 className="my-4 text-2xl font-bold flex-grow">Edit Oral Question</h1>
      </div>
      <hr className="border-1 border-solid border-black my-4" />
      <div className="flex mt-8 bg-blue-200 border border-blue-400 px-4 w-full">
        <FormProvider {...form}>
          <form className="w-full" onSubmit={form.handleSubmit(onSubmit)}>
            <div className="my-6">
              <div className="w-full">
                <label htmlFor={`id_question_type`} className="my-6 block text-sm/6 font-semibold text-gray-900">
                  Question Type
                </label>
                <Controller
                  control={form.control}
                  name="question_type_id"
                  render={({ field }) => (
                    <Select
                      inputRef={field.ref}
                      options={questionOptions}
                      value={questionOptions.find(c => c.value === field.value)}
                      onChange={value => field.onChange(value.value)}
                    />
                  )}
                />
              </div>
            </div>
            <div className="my-6">
              <TextInput
                label="Question"
                name="question_text"
                autoFocus={true}
                placeholder="Enter your question here..."
                rows={5}
              />
            </div>
            <div className="my-6">
              <Input
                label="Academic Context"
                name="academic_context"
                placeholder="Let ChatGPT know the general academic context"
              />
            </div>
            <div className="my-6">
              <Input
                label="Topic / Concept"
                name="topic_concept"
                placeholder="Let ChatGPT know the topic or concept around this question"
              />
            </div>
            <div className="my-6">
              <TextInput
                label="What would be useful for ChatGPT to consider when evaluating the student's transcript?"
                name="ai_context"
              />
            </div>
            <div className="my-6">
              <QuestionCriteria
                criteria={criteria}
                setCriteria={setCriteria}
              />
            </div>
            <div className="my-6">
              <div className={buttonGroupClasses}>
                <button
                  className="mr-8"
                  onClick={() => { form.reset(); setAction(["list"]) }}
                >
                  Cancel
                </button>
                <button className={buttonClasses} type="submit">Save Changes</button>
              </div>
            </div>
          </form>
        </FormProvider>

      </div>
      <DeleteQuestion
        assignmentID={oralAssignmentID}
        questionID={questionID}
        setAction={setAction}
      />
    </div>
  );
};

const AddQuestion = ({ oralAssignmentID, setAction }) => {
  const { id } = useParams();
  const user = useUser();
  const queryClient = useQueryClient();

  const [criteria, setCriteria] = useState([]);
  const form = useForm();
  const mutation = useMutation('new-oral-question', (data) => createNewQuestion(oralAssignmentID, data));
  const questionTypes = useOralQuestionTypes();

  if (user.isLoading || questionTypes.isLoading) {
    return <Loading />;
  }

  // Filter out "needs_reviews" question types as there isn't a review process
  // in standalone Oral assignments
  const questionOptions = questionTypes.data.filter(
    (type) => !type.needs_reviews
  ).map(
    (type) => ({ value: type.id, label: type.name })
  );

  const onSubmit = (data) => {
    console.log("Adding question...")
    data.criteria = criteria
    console.dir(data)
    mutation.mutate(data, {
      onSuccess: (data, values) => {
        queryClient.invalidateQueries(["admin-embedded-oral-questions"]);
        form.reset();
        setAction(["list"]);
      },
      onError: (error, variables, context) => {
        console.log(error.response, variables, context);
      }
    });
  }

  const buttonGroupClasses = classNames("flex items-center float-right my-6", {})

  return (
    <div className="p-6">
      <div className="flex flex-row items-center">
        <h1 className="my-4 text-2xl font-bold flex-grow">Add Oral Question</h1>
      </div>
      <hr className="border-1 border-solid border-black my-4" />
      <div className="flex mt-8 bg-blue-200 border border-blue-400 px-4 w-full">
        <FormProvider {...form}>
          <form className="w-full" onSubmit={form.handleSubmit(onSubmit)}>
            <div className="my-6">
              <div className="w-full">
                <label htmlFor={`id_question_type`} className="my-6 block text-sm/6 font-semibold text-gray-900">
                  Question Type
                </label>
                <Controller
                  control={form.control}
                  name="question_type_id"
                  render={({ field }) => (
                    <Select
                      inputRef={field.ref}
                      options={questionOptions}
                      value={questionOptions.find(c => c.value === field.value)}
                      onChange={value => field.onChange(value.value)}
                    />
                  )}
                />
              </div>
            </div>
            <div className="my-6">
              <TextInput
                label="Question"
                name="question_text"
                autoFocus={true}
                placeholder="Enter your question here..."
                rows={5}
              />
            </div>
            <div className="my-6">
              <Input
                label="Academic Context"
                name="academic_context"
                placeholder="Let ChatGPT know the general academic context"
              />
            </div>
            <div className="my-6">
              <Input
                label="Topic / Concept"
                name="topic_concept"
                placeholder="Let ChatGPT know the topic or concept around this question"
              />
            </div>
            <div className="my-6">
              <TextInput
                label="What would be useful for ChatGPT to consider when evaluating the student's transcript?"
                name="ai_context"
              />
            </div>

            <div className="my-6">
              <QuestionCriteria
                criteria={criteria}
                setCriteria={setCriteria}
              />
            </div>
            <div className="my-6">
              <div className={buttonGroupClasses}>
                <button
                  className="mr-8"
                  onClick={() => { form.reset(); setAction(["list"]) }}
                >
                  Cancel
                </button>
                <button className={buttonClasses} type="submit">Save Changes</button>
              </div>
            </div>
          </form>
        </FormProvider>

      </div>
    </div>
  );
};

const Questions = ({ questions, setAction }) => {

  const handleAdd = () => {
    setAction(["add"])
  }

  return (
    <div className="w-full p-6">
      <div className="flex flex-row items-center">
        <h3 className="text-2xl font-semibold my-6 flex-grow">Timed Oral Questions</h3>
        <div className="flex-initial mx-2">
          <button className={buttonClasses} onClick={handleAdd}>Add Oral Question</button>
        </div>
      </div>
      <hr className="border-1 border-solid border-black my-4" />
      {questions.data.length === 0 && (
        <p className="my-8 border border-blue-300 bg-blue-100 p-4">No questions have been added yet!</p>
      )}
      {questions.data.map((question, index) => (
        <div className="my-6 border border-gray-400 bg-gray-200 p-4" key={`question-${index}`}>

          <div className="flex flex-inline justify-between items-center">
            <h4 className="text-xl font-semibold my-2">Oral Question #{index + 1}</h4>
            <button
              className="flex flex-inline space-x-2 hover:text-gray-600"
              onClick={() => setAction(["edit", question.id])}
            >
              <svg fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                <path strokeLinecap="round" strokeLinejoin="round" d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L10.582 16.07a4.5 4.5 0 0 1-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 0 1 1.13-1.897l8.932-8.931Zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0 1 15.75 21H5.25A2.25 2.25 0 0 1 3 18.75V8.25A2.25 2.25 0 0 1 5.25 6H10" />
              </svg>
              <span>Edit</span>
            </button>
          </div>
          <p className="m-4">{question.question_type.name} - {question.question_type.explanation}</p>
          <span className="text-xl italic m-4">{question.question_text}</span>
        </div>
      ))}
    </div>
  )
}

const Step6 = (props) => {
  const { id } = useParams();
  const history = useHistory();
  const user = useUser();
  const [action, setAction] = useState(["list"]);

  const questions = useEmbeddedOralQuestions(id);
  const {
    handleSubmit, setValue, setError, clearErrors, register, unregister, watch, formState: { errors, isDirty }
  } = useForm();
  const [showDirtyMessage, setShowDirtyMessage] = useState(false);
  const {
    setAssignmentDraft, assignmentDraft, goToNextStep, help, setCanClose
  } = props;


  useEffect(() => {
    if (isDirty) {
      setShowDirtyMessage(true);
      setCanClose(false);
    } else {
      setShowDirtyMessage(false);
      setCanClose(true);
    }
  }, [isDirty]);

  const onSubmit = (data) => {
    let url = `/assignments/${id}`;
    history.push(url);
  };

  const closeModal = () => {
    setShowModal(false);
  }

  if (user.isLoading || questions.isLoading) return <Loading />;

  let actionComponent = null;

  const commonProps = {
    setAction: setAction,
    oralAssignmentID: assignmentDraft.oral_assignment,
  }

  switch (action[0]) {
    case "list":
      actionComponent = <Questions questions={questions} {...commonProps} />;
      break;
    case "add":
      actionComponent = <AddQuestion questions={questions} {...commonProps} />;
      break;
    case "edit":
      actionComponent = <EditQuestion questionID={action[1]} {...commonProps} />;
      break;
    default:
      actionComponent = <Questions questions={questions} {...commonProps} />;
  }

  return (
    <React.Fragment>
      <LeaveWarning when={showDirtyMessage} />
      <StepSection noPadding fullWidth shadow={false} bgGray transparentContainer>
        {actionComponent}
      </StepSection>
    </React.Fragment>
  );
};

export default Step6;
