import React from 'react';

import { useParams, useLocation } from 'react-router-dom';
import { useQuery } from 'react-query';

import ReactMarkdown from 'react-markdown';
import relativeLinks from 'remark-relative-links';
import { getHelp } from '../../queries/help';
import Navigation from '../../components/navigation/navigation';
import ScrollArea from '../../components/scroll-area/scroll-area';


import styles from './help.module.scss';


const Help = (props) => {
  const location = useLocation();
  let finalPath = location.pathname;
  finalPath = finalPath.substring(1);

  const help = useQuery(['help', finalPath], () => getHelp(finalPath), { cacheTime: 60000, staleTime: 60000, retry: 2, retryDelay: 200 });

  if (help.isLoading) {
    return (
      <div className={styles.container}>
        <Navigation title="Help" />
        <ScrollArea>
          Loading...
        </ScrollArea>
      </div>
    );
  }

  if (help.isError) {
    return (
      <div className={styles.container}>
        <Navigation title="Help" />
        <ScrollArea>
          Error
        </ScrollArea>
      </div>
    );
  }

  // Markdown plugins
  const markdownPlugins = [
    [relativeLinks, { domainRegex: /https:\/\/github.com\/plventure\/peer\-learning\/blob\/master\// }]
  ]

  return (
    <div className={styles.container}>
      <Navigation title="Help" />
      <ScrollArea>
        <div className={styles.content}>
          <div className={styles.markdown}>
            <ReactMarkdown children={help.data.markdown} remarkPlugins={markdownPlugins} />
          </div>
        </div>
      </ScrollArea>
    </div>
  );
};

export default Help;
